<template>
  <div class="container">
    <div class="filter-container">
      <el-button
          class="filter-item"
          size="mini"
          type="primary"
          icon="el-icon-plus"
          @click="handleCreate"
      >添加
      </el-button>
      <!--      <el-button-->
      <!--          class="filter-item"-->
      <!--          size="mini"-->
      <!--          type="info"-->
      <!--          icon="el-icon-refresh"-->
      <!--          @click="passwordReset"-->
      <!--      >批量密码重置</el-button>-->
      <el-form :inline="true" class="form" size="small" :model="listQuery">
        <el-form-item v-if="show" class="marginBottom">
          <el-input v-model="listQuery.SearchWord" size="mini" class="filter-item" filterable placeholder="请输入组织"
                    style="width:100%" @keydown.enter="getList()" clearable>
          </el-input>
        </el-form-item>
        <!--        <el-form-item class="marginBottom">-->
        <!--          <el-input v-model="listQuery.strUser" size="mini" label="" placeholder="请输入用户名" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item class="marginBottom">-->
        <!--          <el-input v-model="listQuery.strName" size="mini" label="" placeholder="请输入姓名" />-->
        <!--        </el-form-item>-->
        <!--        <el-form-item class="marginBottom">-->
        <!--          <el-select v-model="listQuery.strGroupTypeId" size="mini" filterable style="width:100%" class="filter-item" placeholder="请选择组别" @change="groupChange">-->
        <!--            <el-option v-for="(item, index) in zbList" :key="index" :label="item.strGroupName" :value="item.strGroupTypeName" />-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item class="marginBottom">-->
        <!--          <el-select v-model="listQuery.strCollectionLocationId" filterable size="mini" class="filter-item" placeholder="请选择书屋" @change="selectSW">-->
        <!--            <el-option v-for="(item, index) in swList" :key="index" :label="item.strName" :value="item.strCollectionLocation" />-->
        <!--          </el-select>    -->
        <!--        </el-form-item>   -->
        <el-form-item class="marginBottom">
          <el-button
              class="filter-item btnBottom"
              type="primary"
              icon="el-icon-search"
              size="mini"
              @click="getList">查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        @selection-change="mulSelect"
    >
      <el-table-column label="组织" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrOrganizationName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="用户名"
          prop="strUser"
          align="center"
      >
        <template v-slot="{row}">
          <span>{{ row.StrUserName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="姓名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrNickName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{row}">
          <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right"
          width="190px"
      >
        <template v-slot="{row}">
          <!-- v-if="username === 'initAdmin'" -->
          <!--          <el-button type="info" size="mini" style="width:78px" @click="passwordReset(row)">密码重置</el-button>-->
          <el-button type="primary" size="mini" style="width:78px" @click="handleUpdate(row)">信息编辑</el-button>
          <el-button
              size="mini"
              type="danger"
              style="width:78px"
              @click="handleDelete(row)"
          >删除用户
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="listQuery.Page"
          :page-size="listQuery.Perpage"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>
    <!--    点击修改按钮弹出对话框-->
    <el-dialog :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false" width="35%">
      <el-tabs v-model="tabActiveName" tab-position="left">
        <el-tab-pane label="基础信息" name="first">
          <el-form
              ref="dataForm"
              :rules="rules"
              :model="formModel"
              label-position="left"
              label-width="80px"
              size="small">
            <el-row>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="选择组织" prop="StrOrganizationID">
                  <el-select v-model="formModel.StrOrganizationID" class="filter-item" placeholder="请选择">
                    <el-option v-for="item in xxList" :key="item.StrID" :label="item.StrName" :value="item.StrID"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="用户名" prop="StrUserName">
                  <el-input v-model="formModel.StrUserName"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="密码" prop="StrPassword">
                  <el-input v-model="formModel.StrPassword"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="姓名" prop="StrNickName">
                  <el-input v-model="formModel.StrNickName"/>
                </el-form-item>
              </el-col>
                <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                  <el-checkbox v-model="formModel.IsOrganizationManager" @change="onOrganizationChange"
                              v-show="showOrganizationBtn">是否是组织管理员
                  </el-checkbox>
                  <el-checkbox v-model="formModel.IsAreaManager" @change="onAreaChange" v-show="showAreaBtn">是否是区域管理员
                  </el-checkbox>
                </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="模块权限" name="second">
          <el-row>
            <el-col :sm="24" :md="24" :lg="24">
              <div class="jurisdiction-content" style="height:450px;overflow:auto;">
                <div class="jurisdiction-content-checkbox">
                  <el-tree
                      ref="tree"
                      :data="PermissionsList"
                      show-checkbox
                      node-key="StrNodeId"
                      :props="defaultProps"
                      :expand-on-click-node="false"
                      empty-text=""
                      :indent="0"
                      default-expand-all/>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <div class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <!--    点击添加按钮弹出对话框-->
    <el-dialog :title="textMap[dialogStatus]" v-model="dialogAddFormVisible" :close-on-click-modal="false" width="30%">
      <el-tabs tab-position="left">
        <el-tab-pane label="基础信息">
          <el-form
              ref="dataFormAdd"
              :rules="rules"
              :model="formModelAdd"
              label-position="left"
              label-width="80px"
              size="small">
            <el-row>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="选择组织" prop="StrOrganizationID">
                  <el-select v-model="formModelAdd.StrOrganizationID" class="filter-item" placeholder="请选择">
                    <el-option v-for="item in xxList" :key="item.StrID" :label="item.StrName" :value="item.StrID"/>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="用户名" prop="StrUserName">
                  <el-input v-model="formModelAdd.StrUserName"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="密码" prop="StrPassword">
                  <el-input v-model="formModelAdd.StrPassword"/>
                </el-form-item>
              </el-col>
              <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                <el-form-item label="姓名" prop="StrNickName">
                  <el-input v-model="formModelAdd.StrNickName"/>
                </el-form-item>
              </el-col>
                            <el-col :xs="xsSize" :sm="xsSize" :md="xsSize" :lg="xsSize" :xl="xsSize">
                              <el-checkbox v-model="formModelAdd.IsOrganizationManager" @change="onAddOrganizationChange"
                                           v-show="showOrganizationBtn">是否是组织管理员
                              </el-checkbox>
                              <el-checkbox v-model="formModelAdd.IsAreaManager" @change="onAddAreaChange" v-show="showAreaBtn">是否是区域管理员
                              </el-checkbox>
                            </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>

      <div class="dialog-footer" style="float: right">
        <el-button size="mini" @click="dialogAddFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="nextStep">下一步</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>

    <!--    点击下一步弹出对话框-->
    <el-dialog :title="textMap[dialogStatus]" v-model="dialogAddNextFormVisible" :close-on-click-modal="false" :show-close="false"
               width="30%">
      <el-tabs tab-position="left">


        <el-tab-pane label="模块权限">
          <el-row>
            <el-col :sm="24" :md="24" :lg="24">
              <div class="jurisdiction-content" style="height:450px;overflow:auto;">
                <div class="jurisdiction-content-checkbox">
                  <el-tree
                      ref="treeAdd"
                      :data="PermissionsList"
                      show-checkbox
                      node-key="StrNodeId"
                      :props="defaultProps"
                      :expand-on-click-node="false"
                      empty-text=""
                      :indent="0"
                      default-expand-all/>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <div class="dialog-footer" style="float: right">
        <el-button size="mini" type="primary" @click="btnAddorMod">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: null,
      total: 0,
      listLoading: true,
      changeListLoading: false,
      showReviewer: false,
      dialogFormVisible: false,
      dialogAddFormVisible: false,
      dialogAddNextFormVisible: false,
      dialogStatus: '',
      showOrganizationBtn: false,
      showAreaBtn: false,

      textMap: {
        update: '编辑',
        create: '添加'
      },
      PermissionsList: [],
      //未使用
      PermissionsList2: [
        {label: "首页", StrNodeId: "首页"},
        {
          label: "图书管理",
          // StrNodeId:"1",
          children: [
            {
              label: "图书档案",
              StrNodeId: "图书档案",
            },
            {
              label: "图书编目",
              StrNodeId: "图书编目",
            },
            {
              label: "图书上架",
              StrNodeId: "图书上架",
            },
            {
              label: "书屋管理",
              StrNodeId: "书屋管理",
            },
            {
              label: "书架管理",
              StrNodeId: "书架管理",
            },
            {
              label: "分类管理",
              StrNodeId: "分类管理",
            },
            {
              label: "报错管理"
            }
          ]
        },
        {
          label: "期刊管理",
          // StrNodeId:"8",
          children: [
            {
              label: "期刊订阅",
              StrNodeId: "期刊订阅",
            },
            {
              label: "期刊登记",
              StrNodeId: "期刊登记",
            },
            {
              label: "期刊类型",
              StrNodeId: "期刊类型",
            }
          ]
        },
        {
          label: "书刊流通",
          // StrNodeId:"12",
          children: [
            {
              label: "快速借还",
              StrNodeId: "快速借还"
            },
            {
              label: "书刊流通",
              StrNodeId: "书刊流通",
            },
            {
              label: "书刊续借",
              StrNodeId: "书刊续借",
            },
            {
              label: "流通概况",
              StrNodeId: "流通概况",
            },
            {
              label: "超期罚款",
              StrNodeId: "超期罚款",
            },
            {
              label: "图书校对",
              StrNodeId: "图书校对",
            },
            {
              label: "书刊丢失损毁登记",
              StrNodeId: "书刊丢失损毁登记",
            },
            {
              label: "书刊丢失损毁阅览",
              StrNodeId: "书刊丢失损毁阅览",
            },
            {
              label: "图书预约记录",
              StrNodeId: "图书预约记录",
            }
          ]
        },
        {
          label: "读者管理",
          // StrNodeId:"20",
          children: [
            {
              label: "读者档案",
              StrNodeId: "读者档案",
            },
            {
              label: "类型管理",
              StrNodeId: "类型管理",
            },
            // {
            //   label:"单元管理",
            //   StrNodeId:"23",
            // },
            {
              label: "部门管理",
              StrNodeId: "部门管理",
            },
            {
              label: "小组管理",
              StrNodeId: "小组管理",
            }
          ]
        },
        {
          label: "报表统计",
          // StrNodeId:"26",
          children: [
            {
              label: "读者借阅排行",
              StrNodeId: "读者借阅排行",
            },
            {
              label: "图书借阅排行",
              StrNodeId: "图书借阅排行",
            },
            // {
            //   label:"到期图书统计",
            //   StrNodeId:"29",
            // },
            // {
            //   label:"图书异常统计",
            //   StrNodeId:"30",
            // },
            {
              label: "图书位置变更日志",
              StrNodeId: "图书位置变更日志",
            },
            {
              label: "图书分类统计",
              StrNodeId: "图书分类统计",
            },
            {
              label: "图书信息统计",
              StrNodeId: "图书信息统计",
            },
            {
              label: "区域学校排行",
              StrNodeId: "区域学校排行",
            },
            // {
            //   label:"图书超期罚款",
            //   StrNodeId:"34",
            // },
            // {
            //   label:"期刊借阅统计",
            //   StrNodeId:"33",
            // },
            {
              label: "工作量统计",
              StrNodeId: "工作量统计",
            },
            {
              label: "积分统计",
              StrNodeId: "积分统计",
            }
          ]
        },
        {
          label: "数据管理",
          // StrNodeId:"35",
          children: [
            {
              label: "读者信息导入",
              StrNodeId: "读者信息导入",
            },
            {
              label: "借阅信息导入",
              StrNodeId: "借阅信息导入",
            },
            // {
            //   label:"历史借阅信息导入",
            //   StrNodeId:"38",
            // },
            {
              label: "图书信息导入",
              StrNodeId: "图书信息导入",
            }
          ]
        },
        {
          label: "系统设置",
          // StrNodeId:"40",
          children: [
            {
              label: "组织管理",
              StrNodeId: "组织管理",
            },
            {
              label: "用户管理",
              StrNodeId: "用户管理",
            },
            {
              label: "系统设置",
              StrNodeId: "系统设置",
            },
            {
              label: "假期管理",
              StrNodeId: "假期管理",
            },
            {
              label: "封面管理",
              StrNodeId: "封面管理",
            }
          ]
        }],
      //用来排序
      items: [
        {
          title: "首页",
        },
        {
          title: "首页",
        },
        {
          title: "图书管理",
          subs: [
            {
              title: "图书档案",
            },
            {
              title: "图书编目",
            },
            {
              title: "图书上架",
            },
            {
              title: "书屋管理",
            },
            {
              title: "书架管理",
            },
            {
              title: "分类管理",
            },
          ],
        },
        {

          title: "期刊管理",
          subs: [
            {
              title: "期刊订阅",
            },
            {
              title: "期刊登记",
            },
            {
              title: "期刊类型",
            },
          ],
        },
        {
          title: "书刊流通",
          subs: [
            {
              title: "书刊流通",
            },
            {
              title: "书刊续借",
            },
            {
              title: "流通概况",
            },
            {
              title: "超期罚款",
            },
            {
              title: "图书校对",
            },
            {
              title: "书刊丢失损毁登记",
            },
            {
              title: "书刊丢失损毁阅览",
            },
            {
              title: "图书预约记录",
            },
          ],
        },
        {
          title: "读者管理",
          subs: [
            {
              index: "/readerfile",
              title: "读者档案",
            },
            {
              index: "/type",
              title: "类型管理",
            },
            // {
            //   index: "readerregister",
            //   title: "读者证办理"
            // },
            // {
            //   index: "/unit",
            //   title: "单元管理",
            // },
            {
              index: "/grade",
              title: "部门管理",
            },
            {
              index: "/class",
              title: "小组管理",
            },
          ],
        },
        {
          icon: "el-icon-pie-chart",
          index: "/charts",
          title: "报表统计",
          subs: [
            {
              index: "/readerlendranking",
              title: "读者借阅排行",
            },
            // {
            //   index: "readerlendmsg",
            //   title: "读者借阅信息"
            // },
            {
              index: "/booklendranking",
              title: "图书借阅排行",
            },
            // {
            //   index: "permission",
            //   title: "到期图书统计"
            // },
            // {
            //   index: "permission",
            //   title: "图书异常统计"
            // },
            {
              index: "/librarychangedmsg",
              title: "图书位置变更日志",
            },
            {
              index: "/classifiedstatistics",
              title: "图书分类统计",
            },
            {
              index: "/bookmsgstatistics",
              title: "图书信息统计",
            },
            // {
            //   index: "permission",
            //   title: "图书超期罚款"
            // },
            {
              index: "/schoolranking",
              title: "区域学校排行",
            },
            {
              index: "/workloadstatistics",
              title: "工作量统计",
            },
            {
              index: "/integralstatistics",
              title: "积分统计",
            },
          ],
        },
        {
          icon: "el-icon-lx-edit",
          index: "/7",
          title: "数据管理",
          subs: [
            {
              index: "/readerimport",
              title: "读者信息导入",
            },
            {
              index: "/bookimport",
              title: "图书信息导入",
            },
            {
              index: "/circulationimport",
              title: "借阅信息导入",
            },
            // {
            //   index: "/circulationimport",
            //   title: "历史借阅信息导入",
            // },
          ],
        },
        {
          icon: "el-icon-lx-settings",
          index: "/donate",
          title: "系统设置",
          subs: [
            {
              index: "/school",
              title: "组织管理",
            },
            {
              index: "/user",
              title: "用户管理",
            },
            {
              index: "/config",
              title: "系统设置",
            },
            {
              index: "/holiday",
              title: "假期管理",
            },
            {
              index: "/image",
              title: "封面管理",
            },
            {
              index: "/announcement",
              title: "公告管理",
            },
            {
              index: "/publicize",
              title: "宣传栏管理",
            },
          ],
        },
      ],
      tabActiveName: 'first',
      username: '',
      // 组织搜索
      inputFormModel: {},
      dialogPvVisible: false,
      rules: {
        StrOrganizationID: [
          {required: true, message: '请选择组织', trigger: 'blur'}
        ],
        StrPassword: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        StrUserName: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        StrNickName: [
          {required: true, message: '请输入昵称', trigger: 'blur'}
        ]
      },
      downloadLoading: false,
      listQuery: {
        Page: 1,
        Perpage: 10,
        SearchWord: "",
        // strUser: '',
        // strName: '',
        // strGroupTypeId: '',
        // strCollectionLocationId: '',
        // selectRows: []
      },
      listQuery2: {
        Page: 1,
        Perpage: 999,
        OrderBy: {"DTCreatedDate": -1},
        Search: {}
      },
      xsSize: 24,
      smSize: 24,

      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      formModel: {},
      //添加所使用的formModel
      formModelAdd: {},

      xxList: [],
      swList: [],
      zbList: [],
      isSchool: true,
      show: true,
      schoolId: ''
    }
  },
  created() {
    // this.username = localStorage.getItem('userInfo')
    // if (this.username !== 'initAdmin') {
    //   this.listQuery.strSchoolId = localStorage.getItem('schoolId')
    //   this.show = false
    // } else {
    this.show = true
    // }1
    this.getXXList()
    this.getList()
    if (localStorage.getItem('StrID') === 'System') {
      this.showAreaBtn = true
      this.showOrganizationBtn = true
    }
    if ('true' === localStorage.getItem('IsAreaManager')) {
      this.showOrganizationBtn = true
    }
    this.formModel.IsOrganizationManager = false
    this.formModel.IsAreaManager = false

    this.formModelAdd.IsOrganizationManager = false
    this.formModelAdd.IsAreaManager = false
    // this.getSWList()
    // this.getZBList()
  },
  methods: {
    // 多选用户
    mulSelect(rows) {
      this.selectRows = rows
    },
    // 密码重置
    // passwordReset(row) {
    //   let { strId } = row
    //   const strIdArr = []
    //   if (!strId) {
    //     this.selectRows.forEach(item => {
    //       strIdArr.push(item.strId)
    //     })
    //     strId = strIdArr.join(',')
    //   }
    //   this.$confirm('此操作将会重置密码，确定要重置吗?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     resetPassword({ strId: strId, strType: 'admin' }).then((res) => {
    //       if (res.code === 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '重置密码成功!'
    //         })
    //       }
    //     })
    //   }).catch(() => {
    //     this.$message({
    //       type: 'info',
    //       message: '已取消重置密码'
    //     })
    //   })
    // },
    // 获取用户列表
    getList() {
      this.$axios({
        url: this.localpath + 'managers?Page=' + this.listQuery.Page + '&Perpage=' + this.listQuery.Perpage + '&SearchWord=' + this.listQuery.SearchWord.toString(),
        method: 'get',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(response => {
        if (response.data.ok) {
          this.list = response.data.msg
          this.total = response.data.total          //缺TOTAL数据次
          this.listLoading = false
        }
      })
    },
    // 获取组织列表
    getXXList() {
      this.$axios({
        url: this.localpath + 'organizations/search',
        method: 'post',
        data: JSON.stringify(this.listQuery2),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.xxList = res.data.msg
        }
      })
    },
    // 根据id筛选对应的name
    groupChange(val) {
      this.zbList.forEach(item => {
        if (item.strId === val) {
          this.formModel.strGroupTypeName = item.strGroupName
        }
      })
    },
    //点击下一步
    nextStep() {
      this.$refs['dataFormAdd'].validate(valid => {
        if (valid) {
          console.log(this.formModelAdd)
          this.$axios({
            url: this.localpath + 'managers/createManagerFirstStep',
            method: 'post',
            data: JSON.stringify(this.formModelAdd),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.PermissionsList = []
              //
              var permissions = JSON.parse(res.data.msg.Permissions)

              //首页
              var system = permissions.System
              for (var i in system) {
                if (system[i].StrName === '首页' && system[i].Show) {
                  const param = {label: "首页", StrNodeId: "首页"}
                  this.PermissionsList.push(param)
                }
              }
              for (var s in this.items) {
                  // eslint-disable-next-line no-redeclare
                for (var i in permissions) {
                  //   permissions一样
                  if (i === this.items[s].title) {
                    const param = {label: i, children: []}
                    var isexist = false;
                    for (var w in this.items[s].subs) {
                      for (var j in permissions[i]) {
                        if (this.items[s].subs[w].title === permissions[i][j].StrName && permissions[i][j].Show) {
                          isexist = true
                          const paramin = {
                            label: permissions[i][j].StrName,
                            StrNodeId: permissions[i][j].StrName
                          }
                          param.children.push(paramin)
                        }
                      }
                    }
                    if (isexist) {
                      this.PermissionsList.push(param)
                    }
                  }
                }
              }

              this.formModelAdd.Permissions = permissions
              this.formModelAdd.StrID = res.data.msg.StrID


              this.$nextTick(() => {
                this.$refs['dataFormAdd'].clearValidate()
                this.$refs.treeAdd.setCheckedKeys([], true)
              })

              this.dialogAddFormVisible = false
              this.dialogAddNextFormVisible = true
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    },
    // 添加
    handleCreate() {
      this.formModelAdd = {}
      this.formModelAdd.StrPassword = "123456"
      this.formModelAdd.StrCreatorID = localStorage.getItem('StrID')
      this.formModelAdd.StrCreatorName = localStorage.getItem('username')

      this.isSchool = true
      //   this.$set(this.formModel, 'strSchoolId', this.$store.state.user.school)
      // }
      this.dialogStatus = 'create'
      this.dialogAddFormVisible = true

      this.formModelAdd.IsOrganizationManager = false
      this.formModelAdd.IsAreaManager = false
    },
    // 修改
    handleUpdate(row) {
      // if (this.$store.state.user.name === '初始化管理员') {
      //   this.isSchool = false
      // } else {
      this.isSchool = true
      //   this.$set(this.formModel, 'strSchoolId', this.$store.state.user.school)
      // }
      this.dialogStatus = 'update'

      var permiss = []
      this.PermissionsList = []

      //先Show,再Selected
      if (typeof (row.Permissions) === 'string') {
        //如果是字符串格式，转为json    防止json格式调用此函数
        var permissions = JSON.parse(row.Permissions)
      } else {
          // eslint-disable-next-line no-redeclare
        var permissions = row.Permissions
      }

      //首页
      var system = permissions.System
      for (var i in system) {
        if (system[i].StrName === '首页' && system[i].Show) {
          const param = {label: "首页", StrNodeId: "首页"}
          this.PermissionsList.push(param)
          if (system[i].Selected) {
            permiss.push("首页")
          }
        }
      }

      for (var s in this.items) {
          // eslint-disable-next-line no-redeclare
        for (var i in permissions) {
          //   permissions一样
          if (i === this.items[s].title) {
            const param = {label: i, children: []}
            var isexist = false;
            for (var w in this.items[s].subs) {
              for (var j in permissions[i]) {
                if (this.items[s].subs[w].title === permissions[i][j].StrName && permissions[i][j].Show) {
                  isexist = true
                  const paramin = {
                    label: permissions[i][j].StrName,
                    StrNodeId: permissions[i][j].StrName
                  }
                  param.children.push(paramin)
                  if (permissions[i][j].Selected) {
                    permiss.push(permissions[i][j].StrName)
                  }
                }
              }
            }
            if (isexist) {
              this.PermissionsList.push(param)
            }
          }
        }
      }
      this.dialogFormVisible = true;

      this.formModel = row
      // this.formModel.Permissions 是json
      this.formModel.Permissions = permissions
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
        this.$refs.tree.setCheckedKeys(permiss, true)
      })
    },
    // 确认添加或修改
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        var menurange = this.$refs.treeAdd.getCheckedKeys(true)
        //新建为0的字符串,用来传权限
        // this.formModel.Permissions = "0000000000000000000000000000000000000"
        // for (var i = 0; i < menurange.length; i++) {
        //   this.formModel.Permissions = this.changeStr(this.formModel.Permissions, parseInt(menurange[i]), "1")
        // }
        // console.log(this.formModel.Permissions)

        var permissions = this.formModelAdd.Permissions

        //初始化
        for (var j in permissions) {
          for (var k in permissions[j]) {
            permissions[j][k].Selected = false;
          }
        }

        for (var i = 0; i < menurange.length; i++) {
            // eslint-disable-next-line no-redeclare
          for (var j in permissions) {
              // eslint-disable-next-line no-redeclare
            for (var k in permissions[j]) {
              //menurange[i]一定为Show
              if (permissions[j][k].StrName === menurange[i]) {
                permissions[j][k].Selected = true;
              }
            }
          }
        }
        permissions = JSON.stringify(permissions)

        var id = this.formModelAdd.StrID

        this.formModelAdd = {}
        this.formModelAdd.StrID = id
        this.formModelAdd.Permissions = permissions

        this.$axios({
          url: this.localpath + 'managers/updateManagerPermission',
          method: 'post',
          data: JSON.stringify(this.formModelAdd),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
          this.getList()
          this.dialogAddNextFormVisible = false
        })
      }
    else {
      this.$refs['dataForm'].validate(valid => {
        if (valid) {
          //这里出错，有两位数的字符串！
          // var menurange=this.$refs.tree.getCheckedKeys().join().split(',')
          var menurange = this.$refs.tree.getCheckedKeys(true)
          // //新建为0的字符串,用来传权限
          // this.formModel.Permissions = "0000000000000000000000000000000000000"
          // for (var i = 0; i < menurange.length; i++) {
          //   this.formModel.Permissions = this.changeStr(this.formModel.Permissions, parseInt(menurange[i]), "1")
          // }

          var permissions = this.formModel.Permissions

          //初始化
          for (var j in permissions) {
            for (var k in permissions[j]) {
              if (permissions[j][k].Show) {
                permissions[j][k].Selected = false;
              }
            }
          }

          for (var i = 0; i < menurange.length; i++) {
              // eslint-disable-next-line no-redeclare
            for (var j in permissions) {
                // eslint-disable-next-line no-redeclare
              for (var k in permissions[j]) {
                //menurange[i]一定为Show
                if (permissions[j][k].StrName === menurange[i]) {
                  permissions[j][k].Selected = true;
                }
              }
            }
          }
          permissions = JSON.stringify(permissions)
          this.formModel.Permissions = permissions

          this.$axios({
            url: this.localpath + 'managers/' + this.formModel.StrID,
            method: 'patch',
            data: JSON.stringify(this.formModel),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '编辑成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
            this.dialogFormVisible = false
          })
        }
      })
    }
  },
  changeStr(str, index, changeStr) {
    return str.substr(0, index) + changeStr + str.substr(index + changeStr.length);
  },
  // 删除
  handleDelete(row) {
    this.$confirm('确定要删除该用户吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      dangerouslyUseHTMLString: true
    }).then(() => {
      this.$axios({
        url: this.localpath + 'managers/' + row.StrID,
        method: 'delete',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.$notify({
            message: '删除成功',
            type: 'success',
            duration: 2000
          })
        } else {
          this.$notify({
            message: res.data.msg,
            type: 'error'
          })
        }
        this.getList()
      })
    }).catch(() => {
      this.$message({
        type: 'info',
        message: '已取消删除'
      })
    })
  },
  pageSizeChange(Perpage) {
    if (Perpage != null) {
      this.listQuery.Perpage = Perpage
      this.getList()
    }
  },
  currPageChange(Page) {
    if (Page != null) {
      this.listQuery.Page = Page
      this.getList()
    }
  },
  onAreaChange() {
    if (this.formModel.IsAreaManager) {
      this.formModel.IsOrganizationManager = true
    }
  },
  onOrganizationChange() {
    if (!this.formModel.IsOrganizationManager) {
      this.formModel.IsAreaManager = false
    }
  },
    onAddAreaChange() {
      if (this.formModelAdd.IsAreaManager) {
        this.formModelAdd.IsOrganizationManager = true
      }
    },
    onAddOrganizationChange() {
      if (!this.formModelAdd.IsOrganizationManager) {
        this.formModelAdd.IsAreaManager = false
      }
    }
}
}
</script>

<style lang="scss" scoped>
.form {
  // display: flex;
  // align-items: center;
  float: right;

  .marginTop {
    margin-top: 2px;
  }

  .marginBottom {
    margin-bottom: 0;
  }

  .btnBottom {
    margin-bottom: 6px
  }
}

// .filter-container .filter-item {
//   margin-bottom: 0px;
// }
.filter-container {
  display: inline;
  padding-bottom: 10px;
}
</style>
