<template>
  <div>
    <!-- 搜索框 -->
    <div style="margin: 5px 5px 5px 5px;">
      <el-select v-model="searchKey" placeholder="选择搜索条件" size="mini" style="width: 120px;">
        <el-option label="图书名称" value="BookTitle"></el-option>
        <el-option label="图书条码" value="BookBarcode"></el-option>
        <el-option label="读者姓名" value="ReaderName"></el-option>
        <el-option label="读者卡号" value="ReaderCardNumber"></el-option>
      </el-select>
      <el-input
          v-model="searchQuery"
          placeholder="请输入搜索内容"
          clearable
          size="mini"
          style="width: 300px;">
      </el-input>
      <el-button @click="handleSearch" style="margin-left: 10px;" size="mini" type="primary">搜索</el-button>
      <el-button @click="Reset" style="margin-left: 10px;" size="mini" type="warning">重置</el-button>
    </div>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          fixed
          prop="BookTitle"
          label="书名"
          align="center"

      >
      </el-table-column>
      <el-table-column
          fixed
          prop="BookBarcode"
          label="图书条码"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="ReaderName"
          label="读者姓名"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="ReaderCardNumber"
          label="读者卡号"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="AppointmentTime"
          label="预约时间"
          :formatter="formatDate"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="AppointmentStatus"
          label="预约状态"
          :formatter="formatStatus"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="EndAppointmentTime"
          label="过期时间"
          :formatter="formatDate"
          align="center"
      >
        <template v-slot="scope">
        <span v-if="scope.row.EndAppointmentTime && scope.row.EndAppointmentTime !== '0001-01-01T00:00:00Z'">
          {{ formatDate(scope.row, scope.column, scope.row.EndAppointmentTime) }}
        </span>
          <span v-else style="color: #c1bcbc;">————</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="ActualPickupTime"
          label="取书时间"
          :formatter="formatDate"
          align="center"
      >
        <template v-slot="scope">
        <span v-if="scope.row.ActualPickupTime && scope.row.ActualPickupTime !== '0001-01-01T00:00:00Z'">
          {{ formatDate(scope.row, scope.column, scope.row.ActualPickupTime) }}
        </span>
          <span v-else style="color: #c1bcbc;">————</span>
        </template>
      </el-table-column>
<!--      操作按钮-->
      <el-table-column
          label="操作"
          v-slot="scope"
          width="150"
          align="center"
      >
        <el-button v-show="scope.row.AppointmentStatus !== 3 && scope.row.AppointmentStatus !== 2 &&  scope.row.AppointmentStatus !== 4" @click="handleCancel(scope.row)" type="info" plain size="mini">取消</el-button>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <div style="margin-top: 20px; text-align: right">
      <el-pagination
          background
          :total="totalItems"
          :current-page="currentPage"
          :page-size="pageSize"
          :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="handlePageSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
<!--    <el-pagination-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page="currentPage"-->
<!--        :page-size="pageSize"-->
<!--        :total="totalItems"-->
<!--        layout="total, prev, pager, next, jumper"-->
<!--    >-->
<!--    </el-pagination>-->
  </div>
</template>
<script>
export default {
  methods: {
    handleClick(row) {
      console.log(row);
    },
    getList() {
      const param = {
        Page: this.currentPage, // 当前页数
        Perpage: this.pageSize, // 每页显示条数
        OrderBy: "DTCreatedDate",
        Search: {}
      };
      // 传递当前的搜索条件（如果有）
      if (this.searchKey && this.searchQuery !== undefined && this.searchQuery !== null && this.searchQuery !== '') {
        param.Search[this.searchKey] = this.searchQuery;
      }
      this.$axios({
        url: this.localpath + 'bookAppointmentRecord/search',
        method: 'post',
        data: JSON.stringify(param),
        headers: {
          'Content-Type': 'application/json',
          'dataType': 'json',
          'Authorization': localStorage.getItem("token")
        }
      }).then((res) => {
        if (res.data.ok) {
          this.tableData = res.data.msg; // 数据赋值
          this.totalItems = res.data.total; // 总条数
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    handleCurrentChange(page) {
      this.currentPage = page; // 更新当前页
      this.getList(); // 重新获取数据
    },
    handlePageSizeChange(pageSize){
      this.pageSize = pageSize;
      this.getList();
    },
    // 格式化日期
    formatDate(row, column, cellValue) {
      if (!cellValue || cellValue === "0001-01-01T00:00:00Z") return '————'; // 如果值为空，返回空字符串
      const date = new Date(cellValue);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    //格式化状态
    formatStatus(row, column, cellValue) {
      if(cellValue === 0){
        return '预约成功'
      }else if(cellValue === 1){
        return '预约锁定'
      }else if(cellValue === 2){
        return '预约完成'
      }else if(cellValue === 3){
        return '预约取消'
      }else {
        return '预约超期'
      }
    },
    SearchByKey(key, value)
    {
      const param = {
        Page: this.currentPage, // 当前页数
        Perpage: this.pageSize, // 每页显示条数
        OrderBy: "DTCreatedDate",
        Search: {}
      };
      if (key && value !== undefined && value !== null && value !== '') {
        param.Search[key] = value;
      }
      this.$axios({
        url: this.localpath + 'bookAppointmentRecord/search',
        method: 'post',
        data: JSON.stringify(param),
        headers: {
          'Content-Type': 'application/json',
          'dataType': 'json',
          'Authorization': localStorage.getItem("token")
        }
      }).then((res) => {
        if (res.data.ok) {
          console.log(res.data);
          this.tableData = res.data.msg; // 数据赋值
          this.totalItems = res.data.total; // 总条数
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      });
    },
    handleSearch() {
      this.currentPage = 1; // 每次搜索时重置为第一页
      this.SearchByKey(this.searchKey,this.searchQuery); // 进行搜索请求
    },
    Reset()
    {
      this.searchKey = ''
      this.searchQuery = ''
      this.currentPage = 1
      this.getList()
    },
    //取消逻辑
    handleCancel(row) {
      console.log('取消', row.BookBarcode);
      if(row.AppointmentStatus === 3)
      {
        this.$message({
          message: '无法取消已取消的预约',
          type: 'warning'
        });
        return;
      }
      this.$confirm('是否取消该预约记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const param = {
          StrID:row.StrID
        }
        this.$axios({
          url: this.localpath + 'bookAppointmentRecord/cancel',
          method: 'post',
          data: JSON.stringify(param),
          headers: {
            'Content-Type': 'application/json',
            'dataType': 'json',
            'Authorization': localStorage.getItem("token")
          }
        }).then((res) => {
          if (res.data.ok) {
            this.$message({
              type: 'success',
              message: res.data.msg
            });
            this.getList();
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消操作'
        });
      });
    },
  },
  created() {
    this.getList(); // 页面创建时加载数据
  },
  data() {
    return {
      tableData: [], // 表格数据
      currentPage: 1, // 当前页
      pageSize: 10, // 每页显示条数
      totalItems: 0, // 总条数
      searchQuery: '', // 搜索框的输入内容
      searchKey: '', // 默认搜索条件
    };
  }
}
</script>
<style scoped>
.date-separator {
  color: #666666; /* 设置淡色 */
}
</style>
