<template>
  <div class="container">
  <div class="filter-container">
    <el-form :model="ltQuery" :inline="true">
      <el-form-item label="查询条件">
        <el-input v-model.trim="ltQuery.keywords" />
      </el-form-item>

      <el-form-item>
        <el-select v-model="ltQuery.keyName" placeholder="请选择">
          <el-option
              v-for="item in ltQueryList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item :span="3">
        <el-select v-model="ltQuery.StrDepartmentsID" placeholder="请选择部门" @change="njChange">
          <el-option v-for="item in njList" :key="item.StrID" :label="item.StrName" :value="item.StrID" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="ltQuery.StrGroupID" placeholder="请选择小组">
          <el-option v-for="item in bjList" :key="item.StrID" :label="item.StrName" :value="item.StrID" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select @change="overdueChange" v-model="ltQuery.Overdue" placeholder="是否超期">
          <el-option label="全部" :value="false" />
          <el-option label="超期" :value="true" />
<!--          <el-option label="未超期" :value="false" />-->
        </el-select>
      </el-form-item>
      <br>
      <el-form-item>
        <el-date-picker
            v-model="ltQuery.DTStartCreatedDate"
            type="date"
            placeholder="开始日期"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            @change = "choose"
        />
      </el-form-item>

      <el-form-item>
        <el-date-picker
            v-model="ltQuery.DTEndCreatedDate"
            type="date"
            placeholder="结束日期"
            value-format="YYYY-MM-DD"
            :disabled-date="disabledDate"
            @change = "choose"
        />
      </el-form-item>
      <el-form-item>
        <el-radio v-model="ltQuery.IsReturn" label="false">未还</el-radio>
        <el-radio v-model="ltQuery.IsReturn" label="true" :disabled="ltQuery.Overdue">已还</el-radio>
        <el-radio v-model="ltQuery.IsReturn" label="all" :disabled="ltQuery.Overdue">全部</el-radio>
      </el-form-item>
      <el-form-item>
        <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleFilter"
        >查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
            class="filter-item"
            type="warning"
            icon="el-icon-refresh"
            size="mini"
            @click="reset"
        >重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
            v-if="!downloadLoading"
            class="filter-item"
            type="info"
            icon="el-icon-download"
            size="mini"
            @click="handleDownload"
        >导出</el-button>
        <el-button
            v-if="downloadLoading"
            size="mini"
            :loading="downloadLoading"
            class="filter-item"
            type="info"
            style="margin-left:6px"
            icon="el-icon-download"
        >正在导出</el-button>
      </el-form-item>
    </el-form>
  </div>
  <div class="filter-container">
    <el-form :model="ltQuery" :inline="true">


    </el-form>
  </div>
  <el-row :gutter="20">
    <el-col :span="24">
      <el-table
          v-loading="ltcxLoading"
          :data="ltcxList"
          border
          fit
          highlight-current-row
          style="width: 100%;"
      >
        <el-table-column
            label="卡号"
            prop="id"
            align="center"
        >
          <template v-slot="{row}">
            <span>{{ row.StrReaderCardNumber }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrReaderName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="部门" align="center">
          <template v-slot="{row}">
            <span>{{ row.DepartmentName }}</span>
          </template>
        </el-table-column>
          <el-table-column label="小组" align="center">
              <template v-slot="{row}">
                  <span>{{ row.GroupName }}</span>
              </template>
          </el-table-column>
        <el-table-column label="所借图书" show-overflow-tooltip align="center">
          <template v-slot="{row}">
            <span>{{ row.StrPositiveTitle }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图书条码" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrBookBarcode }}</span>
          </template>
        </el-table-column>
        <el-table-column label="借阅时间" align="center">
          <template v-slot="{row}">
            <span>{{ new Date(row.DTCreatedDate).toLocaleString() }}</span>
          </template>
        </el-table-column>
        <el-table-column label="应还时间" align="center">
          <template v-slot="{row}">
            <span>{{  new Date(row.DTReturnDate).toLocaleString()}}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否归还" align="center">
          <template v-slot="{row}">
            <span>{{ row.IsReturn === true? '已归还':'未归还' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作员" align="center">
          <template v-slot="{row}">
            <span>{{ row.StrCreatorName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="超期" align="center">
          <template v-slot="{row}">
            <span>{{ new Date().toLocaleString() >new Date(row.DTReturnDate).toLocaleString()?new Date().toLocaleString()+'超期':new Date().toLocaleString()+'未超期' }}</span>
          </template>
        </el-table-column> -->
      </el-table>
      <div style="margin-top:50px;text-align:right;">
        <el-pagination
            background
            :total="total"
            :current-page="ltQuery.Page"
            :page-size="ltQuery.Perpage"
            :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
            layout="prev,pager,next,jumper,sizes,total"
            @size-change="pageSizeChange"
            @current-change="currPageChange"
        />
      </div>
    </el-col>
  </el-row>
  </div>
</template>

<script>
import {ElMessage} from "element-plus";
import * as XLSX from 'xlsx';
export default {
  name: "survey",
  data(){
    return{
        Start : '00:00:00',
        EndCreated: '23:59:59',
         ltQuery: {
        Page: 1,
        Perpage: 10,
        intNotOn:"1",
        Sort:"-1",
        OrderBy: "DTCreatedDate",
        keywords: '',
        keyName: '',
        DTStartCreatedDate: '',
        DTEndCreatedDate: '',
        IsReturn: 'all',
        DepartmentName:'',
        Overdue:false
      },
      downloadLoading:false,
      ltcxLoading:false,
      formModel: {},
      bmTreeData:{},
      props: {
        label: 'strNodeName'
      },
      bjList:{},
      njList:{},
      ltcxList: [],
      allData:[],
      ltQueryList: [
        {
          name: '图书条码',
          value: 'StrBookBarcode'
        },
        {
          name: '读者卡号',
          value: 'StrReaderCardNumber'
        }
      ],
    }
  },
    created() {
    this.getgradeList()
    this.handleFilter()
  },
  methods: {
    choose() {
      const day1 = new Date(this.ltQuery.DTStartCreatedDate)
      const day2 = new Date(this.ltQuery.DTEndCreatedDate)
      if (day2.getTime() < day1.getTime()) {
        ElMessage.error('日期选择错误')
        this.ltQuery.DTEndCreatedDate = ''
      }
    },
    // 流通查询

    handleFilter() {
      this.ltcxLoading = true
      var param = {
        Page: this.ltQuery.Page,
        Perpage: this.ltQuery.Perpage,
        //intNotOn:"1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        // DTStartCreatedDate:this.ltQuery.DTStartCreatedDate,
        // DTEndCreatedDate:this.ltQuery.DTEndCreatedDate,
        Overdue: this.ltQuery.Overdue,
        Search: {}
      }
      if (this.ltQuery.StrDepartmentsID != "" && this.ltQuery.StrDepartmentsID != null) {
        param.Search["StrDepartmentID"] = this.ltQuery.StrDepartmentsID
      }
      if (this.ltQuery.DepartmentName != "" && this.ltQuery.DepartmentName != null) {
        param.Search["DepartmentName"] = this.ltQuery.DepartmentName
      }
      if (this.ltQuery.DTStartCreatedDate != "" && this.ltQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.ltQuery.DTStartCreatedDate + 'T00:00:00.999Z'
      }
      if (this.ltQuery.DTEndCreatedDate != "" && this.ltQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.ltQuery.DTEndCreatedDate + 'T23:59:59.999Z'
      }
      if (this.ltQuery.StrGroupID != "" && this.ltQuery.StrGroupID != null) {
        param.Search["StrGroupID"] = this.ltQuery.StrGroupID
      }
      if (this.ltQuery.IsReturn == "true") {
        param.Search["IsReturn"] = true
      } else if (this.ltQuery.IsReturn == "false") {
        param.Search["IsReturn"] = false
      }
      if (this.ltQuery.keywords != "" && this.ltQuery.keyName != '') {
        param.Search[this.ltQuery.keyName] = this.ltQuery.keywords
      }
      this.$axios({
        url: this.localpath + 'borrowrecords/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          console.log("111" + res.data.msg)
          this.ltcxList = res.data.msg
          this.total = res.data.total
          this.ltcxLoading = false
        }
      })
    },
    overdueChange() {
      if (this.ltQuery.Overdue) {
        this.ltQuery.IsReturn = 'false'
      }
    },
    //重置
    reset() {

      this.ltQuery.Page = 1
      this.ltQuery.Perpage = 10
      this.ltQuery.keywords = ''
      this.ltQuery.keyName = ''
      this.ltQuery.DTStartCreatedDate = ''
      this.ltQuery.DTEndCreatedDate = ''
      this.ltQuery.IsReturn = 'all'
      this.ltQuery.StrGroupID = ''
      this.ltQuery.StrDepartmentsID = ''
      this.bjList = []

    },
    disabledDate(time) {
      return time.getTime() > Date.now()
    },

// 根据部门id获取部门名称
    njChange(val) {
      this.ltQuery.StrGroupID = ''
      const param = {
        Page: 1,
        Perpage: 9999,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {
          StrDepartmentID: val
        }
      }
      this.$axios({
        url: this.localpath + 'groups/search',
        // url: this.localpath + 'units',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.bjList = res.data.msg
        }
      })
    },
    // 获取小组
    getgradeList() {
      this.$axios({
        url: this.localpath + 'departments',
        // url: this.localpath + 'units',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.njList = res.data.msg
        }
      })
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      var param = {
        Page: this.ltQuery.Page,
        Perpage: this.ltQuery.Perpage,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        DTStartCreatedDate: this.ltQuery.DTStartCreatedDate,
        DTEndCreatedDate: this.ltQuery.DTEndCreatedDate,
        Overdue: this.ltQuery.Overdue,
        Search: {}
      }
      if (this.ltQuery.StrDepartmentsID != "" && this.ltQuery.StrDepartmentsID != null) {
        param.Search["StrDepartmentID"] = this.ltQuery.StrDepartmentsID
      }

      if (this.ltQuery.DTStartCreatedDate != "" && this.ltQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.ltQuery.DTStartCreatedDate
      }
      if (this.ltQuery.DTEndCreatedDate != "" && this.ltQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.ltQuery.DTEndCreatedDate
      }
      // if(this.ltQuery.StrDepartmentID!="" && this.ltQuery.StrDepartmentID!=null ){
      //   param.Search["StrDepartmentID"]=this.ltQuery.StrDepartmentID
      // }
      if (this.ltQuery.StrGroupID != "" && this.ltQuery.StrGroupID != null) {
        param.Search["StrGroupID"] = this.ltQuery.StrGroupID
      }
      if (this.ltQuery.IsReturn === "true") {
        param.Search["IsReturn"] = true
      } else if (this.ltQuery.IsReturn === "false") {
        param.Search["IsReturn"] = false
      }
      if (this.ltQuery.keyName != "") {
        param.Search[this.ltQuery.keyName] = this.ltQuery.keywords
      }

      this.$axios({
        url: this.localpath + 'borrowrecords/export',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        responseType: 'blob',
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var date = new Date()
        let timem = date.getTime();
        const fileName = "读者借阅信息" + timem + ".xlsx";
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {
        this.downloadLoading = false;
      })
    },
    pageSizeChange(Perpage) {
      this.ltQuery.Perpage = Perpage
      this.handleFilter()
    },
    currPageChange(Page) {
      this.ltQuery.Page = Page
      this.handleFilter()
    },

    reqData() {
      this.ltcxLoading = true;
      let allRecords = [];  // 用来保存所有请求到的数据
      let currentPage = 1;  // 当前请求的页数
      const totalPerPage = 100; // 每页请求的最大条数
      const totalPages = Math.ceil(this.total / totalPerPage); // 总页数

      const param = {
        Perpage: totalPerPage,  // 每次请求100条
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        DTStartCreatedDate: this.ltQuery.DTStartCreatedDate,
        DTEndCreatedDate: this.ltQuery.DTEndCreatedDate,
        Overdue: this.ltQuery.Overdue,
        Search: {}
      };

      // 添加筛选条件
      if (this.ltQuery.StrDepartmentsID) param.Search["StrDepartmentID"] = this.ltQuery.StrDepartmentsID;
      if (this.ltQuery.DTStartCreatedDate) param.Search["DTStartCreatedDate"] = this.ltQuery.DTStartCreatedDate;
      if (this.ltQuery.DTEndCreatedDate) param.Search["DTEndCreatedDate"] = this.ltQuery.DTEndCreatedDate;
      if (this.ltQuery.StrGroupID) param.Search["StrGroupID"] = this.ltQuery.StrGroupID;
      if (this.ltQuery.IsReturn === "true") param.Search["IsReturn"] = true;
      else if (this.ltQuery.IsReturn === "false") param.Search["IsReturn"] = false;
      if (this.ltQuery.keyName) param.Search[this.ltQuery.keyName] = this.ltQuery.keywords;

      // 请求单个页面的数据
      const fetchPageData = () => {
        param.Page = currentPage;  // 设置当前页

        this.$axios({
          url: this.localpath + "borrowrecords/search",
          method: "post",
          data: JSON.stringify(param),
          headers: {
            "Content-Type": "application/json",
            dataType: "json",
            Authorization: localStorage.getItem("token"),
          },
        }).then((res) => {
          if (res.data.ok) {
            // 将当前页的数据合并到 allRecords 中
            allRecords = [...allRecords, ...res.data.msg];

            // 如果还没有请求完所有页面的数据
            if (currentPage < totalPages) {
              currentPage++;  // 增加页数
              fetchPageData();  // 请求下一页
            } else {
              // 所有数据请求完毕后，处理数据
              this.allData = allRecords;
              this.total = res.data.total; // 设置总数据量
              this.ltcxLoading = false;  // 关闭加载动画
              this.exportToExcel();  // 导出数据
            }
          }
        });
      };

      // 启动数据请求
      fetchPageData();
    },
    exportToExcel() {
      if (!this.allData || this.allData.length === 0) {
        this.$message.error("无数据可导出！");
        return;
      }
      // 映射字段名为中文标题
      const headerMapping = {
        DepartmentName: "部门",
        GroupName: "小组",
        StrBookBarcode: "条形码",
        StrReaderName: "读者名称",
        StrReaderCardNumber: "读者卡号",
        StrPositiveTitle: "书名",
        DTBorrowDate: "借阅日期",
        DTReturnDate: "归还日期",
        IsReturn: "是否归还",
        IntRenewCount: "续借次数",
        StrType: "类型",
      };

      // 日期格式化函数
      const formatDate = (date) => {
        if (!date) return "";
        const d = new Date(date);
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

      // 替换字段名和转换数据
      const formattedData = this.allData.map((item) => {
        const newItem = {};
        for (const key in headerMapping) {
          if (key === "IsReturn") {
            // 将是否归还的布尔值转换为中文
            newItem[headerMapping[key]] = item[key] ? "已归还" : "未归还";
          } else if (key === "DTBorrowDate" || key === "DTReturnDate") {
            // 格式化日期
            newItem[headerMapping[key]] = formatDate(item[key]);
          } else {
            // 其他字段直接映射
            newItem[headerMapping[key]] = item[key];
          }
        }
        return newItem;
      });

      // 创建 Excel 工作表
      const ws = XLSX.utils.json_to_sheet(formattedData);

      // 创建 Excel 工作簿
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "借阅信息");

      // 导出 Excel 文件
      const fileName = `借阅信息_${new Date().getTime()}.xlsx`;
      XLSX.writeFile(wb, fileName);
    }

  }
  }
</script>

<style scoped>

</style>
