<template>
  <div class="home">
    <!-- 头部 -->
    <el-row>
      <el-col :span="24">
        <div class="header-left">
          <el-dropdown :hide-on-click="false">
            <span class="el-dropdown-link" style="font-size:18px;">
              {{ $route.meta.title }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goDataboard">大数据智慧图书馆</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <div class="header-right">
          <div class="header-user-con">
            <!-- 用户头像 -->
            <div class="user-avator">
              <img :src="image" class="user-avator"/>
            </div>
            <!-- 用户名下拉菜单 -->
            <el-dropdown
                class="user-name"
                trigger="click"
                @command="handleCommand"
            >
              <span class="el-dropdown-link">
                {{ username }}
                <i class="el-icon-caret-bottom"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item divided command="loginout"
                  >退出登录
                  </el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 中间 -->
    <el-row class="menu">
      <el-col :span="14" class="menu-content">
        <div class="menu-list">
          <div
              class="menu-item"
              v-for="(item, index) in menuList"
              :key="index"
              @click="goHome(item.index)"
          >
            <div :style="{ background: item.color }">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
          </div>
          <!-- <a class="menu-item" v-for="(item, index) in menuList" :key="index" href="#/dashboard2">
            <div :style="{ background: item.color }">
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </div>
          </a> -->
        </div>
      </el-col>
    </el-row>
<!--    <el-dialog-->
<!--        title="提示"-->
<!--        v-model="dialogVisible"-->
<!--        width="20%"-->
<!--        center>-->
<!--      <div style="margin: auto;text-align: center !important;">-->
<!--        <el-form ref="register">-->
<!--          <el-form-item prop="StrUserName">-->
<!--            <el-input v-model="param.StrPassword" placeholder="请输入用户名"/>-->
<!--          </el-form-item>-->
<!--          <el-form-item prop="StrPassword">-->
<!--            <el-input placeholder="请输入密码" v-model="param.StrPassword" @keyup.enter="login()"-->
<!--                      type="password"/>-->
<!--          </el-form-item>-->
<!--          <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>-->
<!--        </el-form>-->
<!--      </div>-->
<!--      </el-dialog>-->
    <change-password></change-password>
  </div>
</template>
<script>
import changePassword from "@/components/changePassword";
import {DATABOARD_URL} from "@/utils/constants";
export default {
  components:{
    changePassword
  },
  data() {
    return {
      username: localStorage.getItem("username"),
      image: localStorage.getItem("schoolimg"),
      menuList: [],
      route: [],
      items: [
        {
          icon: "el-icon-lx-home",
          index: "/dashboard2",
          title: "首页",
        },
        {
          icon: "el-icon-lx-read",
          index: "/bookManagement",
          title: "图书管理",
          subs: [
            {
              index: "/bookfile",
              title: "图书档案",
            },
            {
              index: "/bookcatalog",
              title: "图书编目",
            },
            {
              index: "/bookputon",
              title: "图书上架",
            },
            {
              index: "/library",
              title: "书屋管理",
            },
            {
              index: "/bookshelf",
              title: "书架管理",
            },
            {
              index: "/classification",
              title: "分类管理",
            },
            {
              index: "/errorManager",
              title: "报错管理"
            }
          ],
        },
        {
          icon: "el-icon-lx-copy",
          index: "/journal",
          title: "期刊管理",
          subs: [
            {
              index: "/journalsubscribe",
              title: "期刊订阅",
            },
            {
              index: "/journalregister",
              title: "期刊登记",
            },
            {
              index: "/journaltype",
              title: "期刊类型",
            },
          ],
        },
        {
          icon: "el-icon-refresh",
          index: "/allcirculation",
          title: "书刊流通",
          subs: [
            {
              index: "/fastborrowandreturn",
              title: "快速借还"
            },
            {
              index: "/circulation",
              title: "书刊流通",
            },
            {
              index: "/renew",
              title: "书刊续借",
            },
            {
              index: "/survey",
              title: "流通概况",
            },
            {
              index: "/overdue",
              title: "超期罚款",
            },
            {
              index: "/proofreading",
              title: "图书校对",
            },
            {
              index: "/lostandbreaklist",
              title: "书刊丢失损毁登记",
            },
            {
              index: "/lostandbreakregister",
              title: "书刊丢失损毁阅览",
            },
            {
              index: "/lostandbreakregister",
              title: "图书预约记录",
            },
          ],
        },
        {
          icon: "el-icon-lx-friend",
          index: "/reader",
          title: "读者管理",
          subs: [
            {
              index: "/readerfile",
              title: "读者档案",
            },
            {
              index: "/type",
              title: "类型管理",
            },
            // {
            //   index: "readerregister",
            //   title: "读者证办理"
            // },
            // {
            //   index: "/unit",
            //   title: "单元管理",
            // },
            {
              index: "/grade",
              title: "部门管理",
            },
            {
              index: "/class",
              title: "小组管理",
            },
          ],
        },
        {
          icon: "el-icon-pie-chart",
          index: "/charts",
          title: "报表统计",
          subs: [
            {
              index: "/readerlendranking",
              title: "读者借阅排行",
            },
            // {
            //   index: "readerlendmsg",
            //   title: "读者借阅信息"
            // },
            {
              index: "/booklendranking",
              title: "图书借阅排行",
            },
            // {
            //   index: "permission",
            //   title: "到期图书统计"
            // },
            // {
            //   index: "permission",
            //   title: "图书异常统计"
            // },
            {
              index: "/librarychangedmsg",
              title: "图书位置变更日志",
            },
            {
              index: "/classifiedstatistics",
              title: "图书分类统计",
            },
            {
              index: "/bookmsgstatistics",
              title: "图书信息统计",
            },
            // {
            //   index: "permission",
            //   title: "图书超期罚款"
            // },
            {
              index: "/schoolranking",
              title: "区域学校排行",
            },
            {
              index: "/workloadstatistics",
              title: "工作量统计",
            },
            {
              index: "/integralstatistics",
              title: "积分统计",
            },
          ],
        },
        {
          icon: "el-icon-lx-edit",
          index: "/7",
          title: "数据管理",
          subs: [
            {
              index: "/readerimport",
              title: "读者信息导入",
            },
            {
              index: "/bookimport",
              title: "图书信息导入",
            },
            {
              index: "/circulationimport",
              title: "借阅信息导入",
            },
            // {
            //   index: "/circulationimport",
            //   title: "历史借阅信息导入",
            // },
          ],
        },
        {
          icon: "el-icon-lx-settings",
          index: "/donate",
          title: "系统设置",
          subs: [
            {
              index: "/school",
              title: "组织管理",
            },
            {
              index: "/user",
              title: "用户管理",
            },
            {
              index: "/config",
              title: "系统设置",
            },
            {
              index: "/holiday",
              title: "假期管理",
            },
            {
              index: "/image",
              title: "封面管理",
            },
            {
              index: "/announcement",
              title: "公告管理",
            },
            {
              index: "/publicize",
              title: "宣传栏管理",
            },
          ],
        },
      ],

    };
  },
  methods: {
    goDataboard() {
      // this.$router.push('/homestatiscs')
      // window.location.href = 'http://databoard.sanyue100.com/'
      window.location.href = DATABOARD_URL
    },
    // 用户名下拉菜单选择事件
    handleCommand(command) {
      if (command == "loginout") {
        localStorage.clear();
        sessionStorage.clear();
        this.$router.push("/login");
      }
    },
    getroute() {
      //json格式化
      var rou = JSON.parse(localStorage.getItem("route"));
      var system = rou.System
      //权限这里改变    System只需要管首页
      for (var i in system) {
        if (system[i].StrName === '首页' && system[i].Selected) {
          this.route.push(this.items[0])
          break;
        }
      }
      for (var j in this.items) {
          // eslint-disable-next-line no-redeclare
        for (var i in rou) {
          //一级标题对上了
          if (i === this.items[j].title) {
            //添加父亲路由
            const param = {
              icon: this.items[j].icon,
              index: this.items[j].index,
              title: this.items[j].title,
              subs: [],
            };
            this.route.push(param);
            //判断子路由是否存在，不存在则删除父路由
            var isexist = false;
            for (var w in this.items[j].subs) {
              for (var k in rou[i]) {
                if (rou[i][k].Selected) {
                  isexist = true
                  if (this.items[j].subs[w].title === rou[i][k].StrName) {
                    this.route[this.route.length - 1].subs.push(this.items[j].subs[w])
                  }
                }
              }
            }
            if (!isexist) {
              this.route.pop()
            }
          }
        }
      }
    },
    getMenuList() {
      var colorArr = [
        "#8DC153",
        "#EC87C1",
        "#37BD9C",
        "#3BAEDA",
        "#F98852",
        "#FB6E52",
        "#E6A23C",
        "#F56C6C",
        "#67C23A",
      ];
      for (let i = 0; i < this.route.length; i++) {
        let menu = {
          title: this.route[i].title.substring(0, 2),
          icon: this.route[i].icon,
          color: colorArr[i],
          //这个要为他的第一个subs
          index: this.route[i].subs
              ? this.route[i].subs[0].index
              : "/dashboard2",
        };
        this.menuList.push(menu);
      }
    },
    goHome(index) {
      switch (index) {
        case '/bookfile':
          index = 'bookManagement' + index;
          break;
        case '/journalsubscribe':
          index = 'journal' + index;
          break;
        case '/readerlendranking':
          index = 'charts' + index;
          break;
        case '/circulation':
          index = 'allcirculation' + index;
          break;
        case '/readerfile':
          index = 'reader' + index;
          break;
        case '/readerimport':
          index = '7' + index;
          break;
        case '/school':
          index = 'donate' + index;
          break;
      }
      //将路径存放到localStorage中
      localStorage.setItem("active", index);
      this.$router.push(index);
    },
  },
  created() {
    this.getroute();
    this.getMenuList();
  },
};
</script>
<style scoped>
.home {
  height: 100%;
  background: url("../assets/img/home-bg.jpg") no-repeat;
  background-size: 100% 100%;
}

.el-row:first-child {
  padding: 20px 15px;
}

.header-left {
  float: left;
  height: 50px;
  line-height: 50px;
  font-size: 30px;
  color: #fff;
}

.header-right {
  float: right;

}

.header-user-con {
  display: flex;
  height: 50px;
  align-items: center;
}

.user-avator {
  margin-right: 5px;
}

.user-avator img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.menu {
  height: 100%;
}

.menu-content {
  margin: 100px auto;
  height: 100%;
}

.menu-list {
  height: 60%;
  background: hsla(0, 19%, 92%, 0.7);
}

.menu-item {
  float: left;
  margin: 50px 0 0 40px;
  cursor: pointer;
  color: #fff;
  font-size: 20px;
  text-align: center;
  /* transition: all 1s linear 0s; */
}

.menu-item div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  height: 150px;
  margin: 5px auto;
  border-radius: 6px;
}

.menu-item div:hover {
  transform: scale(1.1, 1.1);
}

.menu-item i {
  padding-bottom: 20px;
}

</style>
