import {createRouter, createWebHashHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
    {
        path: '/',
        component: () => import('../views/SyHome.vue'),
        meta: {
            title: '图书馆集群管理系统'
        }
    },{
        path:'/homestatiscs',
        component: ()=>import('../views/Statistics/HomeStatiscs.vue'),
        meta:{
            title:'流通数据统计'
        }
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                meta: {
                    title: '首页'
                },
                component: () => import (
                "../views/Dashboard.vue")
            },
            {
                path: "/dashboard2",
                name: "Dashboard2",
                meta: {
                    title: '首页'
                },
                component: () => import (
                    "../views/Dashboard2.vue")
            },
            {
                path: "/bookfile",
                name: "BookFile",
                meta: {
                    title: '图书档案'
                },
                component: () => import (
                    "../views/BookManagement/BookFile.vue")
            },

            {
                path: "/bookcatalog",
                name: "BookCatalog",
                meta: {
                    title: '图书编目'
                },
                component: () => import (
                    "../views/BookManagement/BookCatalog.vue")
            },
            {
                path: "/bookputon",
                name: "BookPuton",
                meta: {
                    title: '图书上架'
                },
                component: () => import (
                    "../views/BookManagement/BookPuton.vue")
            },
            {
                path: "/library",
                name: "Library",
                meta: {
                    title: '书屋管理'
                },
                component: () => import (
                    "../views/BookManagement/Library.vue")
            },
            {
                path: "/bookshelf",
                name: "BookShelf",
                meta: {
                    title: '书架管理'
                },
                component: () => import (
                /* webpackChunkName: "table" */
                "../views/BookManagement/BookShelf.vue")
            },
            {
                path: "/errorManager",
                name: "ErrorManager",
                meta: {
                    title: '报错管理'
                },
                component: () => import (
                    "../views/BookManagement/ErrorManager.vue")
            },
            {
                path: "/classification",
                name: "Classification",
                meta: {
                    title: '分类管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/BookManagement/Classification.vue")
            },
            {
                path: "/journalsubscribe",
                name: "JournalSubscribe",
                meta: {
                    title: '期刊订阅'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Journal/JournalSubscribe.vue")
            },
            {
                path: "/journaltype",
                name: "JournalType",
                meta: {
                    title: '期刊类型'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Journal/JournalType.vue")
            },
            {
                path: "/journalregister",
                name: "JournalRegister",
                meta: {
                    title: '期刊登记'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Journal/JournalRegister.vue")
            },
            {
                path: "/journalcirculation",
                name: "JournalCirculation",
                meta: {
                    title: '期刊流通'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Journal/JournalCirculation.vue")
            },
            {
                path: "/fastborrowandreturn",
                name: "fastBorrowAndReturn",
                meta: {
                    title: '快速借还'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/fastBorrowAndReturn")
            },
            {
                path: "/circulation",
                name: "Circulation",
                meta: {
                    title: '书刊流通'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/Circulation.vue")
            },
            // {
            //     path: "/test",
            //     name: "Test",
            //     meta: {
            //         title: '测试'
            //     },
            //     component: () => import (
            //         /* webpackChunkName: "table" */
            //         "../views/Circulation/Test.vue")
            // },
            {
                path: "/renew",
                name: "Renew",
                meta: {
                    title: '书刊续借'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/Renew.vue")
            },
            {
                path: "/survey",
                name: "Survey",
                meta: {
                    title: '流通概况'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/Survey.vue")
            },
            {
                path: "/overdue",
                name: "OverDue",
                meta: {
                    title: '超期处理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/OverDue.vue")
            },
            {
                path: "/proofreading",
                name: "Proofreading",
                meta: {
                    title: '图书校对'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/Proofreading.vue")
            },{
                path: "/lostandbreaklist",
                name: "LostAndBreakList",
                meta: {
                    title: '书刊丢失损毁登记'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/LostAndBreakRegister.vue")
            },
            {
                path: "/lostandbreakregister",
                name: "LostAndBreakRegister",
                meta: {
                    title: '书刊丢失损毁阅览'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/LostAndBreakList.vue")
            },

            {
                path: "/Appointment",
                name: "Appointment",
                meta: {
                    title: '图书预约记录'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Circulation/Appointment.vue")
            },
            {
                path: "/readerfile",
                name: "ReaderFile",
                meta: {
                    title: '读者档案'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Reader/ReaderFile.vue")
            },{
                path: "/type",
                name: "Type",
                meta: {
                    title: '类型管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Reader/Type.vue")
            },{
                path: "/readerregister",
                name: "ReaderRegister",
                meta: {
                    title: '读者证办理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Reader/ReaderRegister.vue")
            },
            // {
            //     path: "/unit",
            //     name: "Unit",
            //     meta: {
            //         title: '单元管理'
            //     },
            //     component: () => import (
            //         /* webpackChunkName: "table" */
            //         "../views/Reader/Unit.vue")
            // },
            {
                path: "/grade",
                name: "Grade",
                meta: {
                    title: '部门管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Reader/Grade.vue")
            },{
                path: "/class",
                name: "Class",
                meta: {
                    title: '小组管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Reader/Class.vue")
            },
            {
                path: "/readerlendranking",
                name: "ReaderLendRanking",
                meta: {
                    title: '读者借阅排行'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/ReaderLendRanking.vue")
            },
            {
                path: "/bookLendRanking",
                name: "BookLendRanking",
                meta: {
                    title: '图书借阅排行'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/BookLendRanking.vue")
            },
            {
                path: "/librarychangedmsg",
                name: "LibraryChangedMsg",
                meta: {
                    title: '图书位置变更日志'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/LibraryChangedMsg.vue")
            },
            {
                path: "/classifiedstatistics",
                name: "ClassifiedStatistics",
                meta: {
                    title: '图书分类统计'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/ClassifiedStatistics.vue")
            },{
                path: "/bookmsgstatistics",
                name: "BookMsgStatistics",
                meta: {
                    title: '图书信息统计'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/BookMsgStatistics.vue")
            },{
                path: "/workloadstatistics",
                name: "WorkloadStatistics",
                meta: {
                    title: '工作量统计'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/WorkloadStatistics.vue")
            },{
                path: "/integralstatistics",
                name: "IntegralStatistics",
                meta: {
                    title: '积分统计'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/IntegralStatistics.vue")
            },{
                path: "/readerimport",
                name: "ReaderImport",
                meta: {
                    title: '读者信息导入'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Import/ReaderImport.vue")
            },
            {
                path: "/schoolranking",
                name: "SchoolRanking",
                meta: {
                    title: '区域学校排行'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Report/SchoolRanking.vue")
            },{
                path: "/bookimport",
                name: "BookImport",
                meta: {
                    title: '图书信息导入'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Import/BookImport.vue")
            },{
                path: "/circulationimport",
                name: "CirculationImport",
                meta: {
                    title: '借阅信息导入'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Import/CirculationImport.vue")
            },{
                path: "/school",
                name: "School",
                meta: {
                    title: '组织管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/School.vue")
            },{
                path: "/school",
                name: "School",
                meta: {
                    title: '组织管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/School.vue")
            }, {
                path: "/user",
                name: "User",
                meta: {
                    title: '用户管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/User.vue")
            }, {
                path: "/config",
                name: "Config",
                meta: {
                    title: '系统设置'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/Config.vue")
            },{
                path: "/holiday",
                name: "Holiday",
                meta: {
                    title: '假期管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/Holiday.vue")
            },
            {
                path: "/image",
                name: "Image",
                meta: {
                    title: '封面管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/Image.vue")
            },
            {
                path: "/announcement",
                name: "Announcement",
                meta: {
                    title: '公告管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/Announcement.vue")
            },
            {
                path: "/publicize",
                name: "Publicize",
                meta: {
                    title: '宣传栏管理'
                },
                component: () => import (
                    /* webpackChunkName: "table" */
                    "../views/Manager/Publicize.vue")
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        /* webpackChunkName: "login" */
        "../views/Login.vue")
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title} | 图书馆集群管理系统`;
    const role = localStorage.getItem('token');
    const time=localStorage.getItem('tokentime')
        console.log(new Date().getTime()-time)
    if (!role && to.path !== '/login') {
        next('/login');
    } else if (to.path !== '/login' && new Date().getTime()-time>27000000){
        next('/login');
    }else{
        // console.log(to.path)
        if(to.path === '/' && localStorage.getItem('IsAreaManager')==='false'){
            next('/dashboard');
        }else{
            next();
        }
    }
});

export default router;
