<template>
  <div class="container">
    <el-row>
      <el-col>
        <el-form :inline="true" size="small" :model="listQuery">
          <el-form-item label="查询条件">
            <el-input v-model="listQuery.keywords" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item>
            <el-select v-model="listQuery.keyName" placeholder="请选择">
              <el-option label="读者类型" value="StrReaderTypeName"/>
              <el-option label="读者姓名" value="StrName"/>
              <el-option label="读者卡号" value="StrReaderCardNumber"/>
              <el-option label="读者部门" value="StrDepartmentName"/>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="单元">
            <el-select v-model="listQuery.unitID" placeholder="请选择">
              <el-option
                  v-for="item in bmData"
                  :key="item.StrID"
                  :label="item.StrName"
                  :value="item.StrID"
              />
            </el-select>
          </el-form-item> -->
          <el-form-item>
            <el-radio v-model="listQuery.Status" label=-1>显示停借</el-radio>
            <el-radio v-model="listQuery.Status" label=1>显示挂失</el-radio>
          </el-form-item>
          <el-form-item label="登记时间">
            <el-date-picker
                v-model="listQuery.DTStartCreatedDate"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
            <el-date-picker
                v-model="listQuery.DTEndCreatedDate"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                :disabled-date="disabledDate"
                @change = "choose"
            />
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="toQuery()"
            >查询
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                @click="getAllList"
            >全部
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                type="success"
                size="mini"
                icon="el-icon-menu"
                @click="goPage(true)"
            >类型管理
            </el-button>
          </el-form-item>
          <!-- <el-form-item>
            <el-button
                class="filter-item"
                type="primary"
                size="mini"
                icon="el-icon-s-home"
                @click="goPage(false)"
            >单元管理</el-button>
          </el-form-item> -->
          <el-form-item>
            <el-button
                v-if="!downloadLoading"
                class="filter-item"
                type="info"
                size="mini"
                icon="el-icon-download"
                @click="handleDownload"
            >导出
            </el-button>
            <el-button
                v-if="downloadLoading"
                :loading="downloadLoading"
                class="filter-item"
                type="info"
                size="mini"
                icon="el-icon-download"
            >正在导出
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
            >读者添加
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button
                class="filter-item"
                size="mini"
                type="primary"
                icon="el-icon-printer"
                @click="AllPrintReader"
            >读者证打印
            </el-button>
          </el-form-item>

        </el-form>
      </el-col>
      <el-col>

      </el-col>
    </el-row>
    <el-row :gutter="20">
      <!-- 表格开始 -->
      <el-col>
        <el-table
            v-loading="listLoading"
            :data="list"
            border
            fit
            highlight-current-row
            style="width: 100%;"
            @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column label="卡号" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrReaderCardNumber }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="工号" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrWorkerNumber }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="单元" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrUnitName }}</span>
            </template>
          </el-table-column> -->
          <el-table-column label="部门" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrDepartmentName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="小组" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrGroupName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型" align="center">
            <template v-slot="{row}">
              <span>{{ row.StrReaderTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template v-slot="{row}">
              <span>{{ row.Status === 0 ? '正常' : row.Status === 1 ? '停借' : '挂失' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="登记日期" align="center">
            <template v-slot="{row}">
              <span>{{ new Date(row.DTCreatedDate).toLocaleDateString() }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              align="center"
              width="240"
              class-name="small-padding fixed-width"
              fixed="right"
          >
            <template v-slot="{row,$index}">

              <el-button type="primary" style="width:52px;" size="mini" @click="handleUpdate(row)">编辑</el-button>



              <el-button v-show="row.HasBindFace === true" style="width:75px;" type="warning" size="mini"
                         @click="unbindFace(row)">人脸解绑
              </el-button>
              <el-button v-show="row.HasBindFace === false" style="width:75px;" type="primary" size="mini"
                         @click="startDig(row)">人脸录入
              </el-button>

<!--              <el-button type="primary" style="width:90px;" size="mini"  @click="startDig(row)">人脸录入 </el-button>-->

              <el-popover v-model="visible" placement="left" :width="420">

                <div style="display: flex; flex-wrap: wrap; gap: 8px; justify-content: center;">

                  <el-button v-show="row.Status === 0" style="width:90px;border: none" type="primary" size="mini"
                             @click="btnGSorTJ(row,'gs')">读者证挂失
                  </el-button>
                  <el-button v-show="row.Status === -1" type="warning" size="mini"
                             style="width:90px;border:none; background:	#BA55D3" @click="btnQXGSorTJ(row,'挂失')">取消挂失
                  </el-button>
                  <el-button v-show="row.Status === 0" style="width:90px;border:none;" type="info" size="mini"
                             @click="btnGSorTJ(row,'tj')">读者证停借
                  </el-button>


                  <el-button v-show="row.Status === 1" type="primary" size="mini"
                             style="width:90px;border:none; background:	#FF8C00" @click="btnQXGSorTJ(row,'停借')">取消停借
                  </el-button>

                  <el-button style="width:90px;border: none" size="mini" type="danger" @click="handleDelete(row,$index)">
                    删除
                  </el-button>
                  <el-button style="width:90px;border: none" size="mini" type="primary" @click="editPsw(row,$index)">
                    修改密码
                  </el-button>
<!--                  <el-button style="width:90px;border: none" size="mini" type="primary" @click="tets(row,$index)">-->
<!--                    打印读者证-->
<!--                  </el-button>-->
                </div>
                <template #reference>
                  <el-button size="mini" style="background: #85da87;color: #ffffff" @click="visible = true">更多</el-button>
                </template>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>

<!--        多行打印参数设置-->

        <el-dialog
            title="参数设置(单位mm)"
            v-model="readerCard"
            width="35%"
            height="50%"
            :before-close="closeReaderCard"
            :custom-class="'dialog-class'"
            @opened="generateBarcodes"
        >
          <ul class="print-set">
            <li class="print-set-item">
              <div class="print-set-title">读者卡</div>
              <div class="print-set-option" style="text-align: left;">行数</div>
              <el-input
                  v-model="readerPrint.row"
                  type="number"
                  :min="1"
                  size="mini"
                  style="width: 70px;margin-left: -30px"/>
              <div class="print-set-option">列数</div>
              <el-input
                  v-model="readerPrint.col"
                  type="number"
                  :min="1"
                  size="mini"
                  style="width: 70px;text-align: left"/>
            </li>
            <li class="print-set-item">
              <div class="print-set-title">边距</div>
              <div class="print-set-option" style="text-align: left;">上</div>
              <el-input
                  v-model="readerPrint.paddingTop"
                  type="number"
                  :min="0"
                  size="mini"
                  style="width: 71px;margin-left: -30px"
              />
              <div class="print-set-option">下</div>
              <el-input
                  v-model="readerPrint.paddingBottom"
                  type="number"
                  :min="0"
                  size="mini"
                  style="width: 70px;text-align: left"
              />
              <div class="print-set-option">左</div>
              <el-input
                  v-model="readerPrint.paddingLeft"
                  type="number"
                  :min="0"
                  size="mini"
                  style="width: 70px;text-align: left"
              />

              <div class="print-set-option">右</div>
              <el-input
                  v-model="readerPrint.paddingRight"
                  type="number"
                  :min="0"
                  size="mini"
                  style="width: 70px;text-align: left"
              />
            </li>
            <li class="print-set-item">
              <div class="print-set-title" style="text-align: left;">边距</div>
              <div class="print-set-option" style="text-align: left;">内边距</div>
              <el-input
                  v-model="readerPrint.paddingRow"
                  type="number"
                  :min="0"
                  size="mini"
                  :max="43"
                  :step="1"
                  style="width: 70px;margin-left: -20px"
              />
<!--              <div class="print-set-option">列距</div>-->
<!--              <el-input-->
<!--                  v-model="readerPrint.paddingCol"-->
<!--                  type="number"-->
<!--                  :min="0"-->
<!--                  size="mini"-->
<!--                  style="width: 70px"-->
<!--              />-->
            </li>
          </ul>

          <div class="dialog-footer" style="text-align: center;margin-top: 20px">
            <el-button @click="preview">预览</el-button>
            <el-button type="primary" @click="printReader">打 印</el-button>
          </div>
          <div  v-show="isPrintShow">
          <div class="print-box">
          <div
              :style="{ transform: `scale(${scale / 100})` }"
          >
            <div id="printCardContainer" ref="printCardContainer">
              <div
                  :style="{
                  display: 'grid',
                  // gridTemplateColumns: 'repeat(2, 1fr)',
                  // gridTemplateRows:'repeat(3, 1fr)',
                  gridTemplateColumns:`repeat(${readerPrint.col},1fr`,
                  gridTemplateRows:`repeat(${readerPrint.row},1fr)`,
                  padding:`${readerPrint.paddingTop}mm ${readerPrint.paddingRight}mm ${readerPrint.paddingBottom}mm ${readerPrint.paddingLeft}mm`,
                  // gap:`${readerPrint.paddingRow}mm ${readerPrint.paddingCol}mm`,
                  // gap:`${readerPrint.paddingRow}mm`,
                  boxSizing:'border-box',
                }"
              >
                <div
                    v-for="(info, index) in selectedRows"
                    :key="index"
                    :style="{
                    // border: '1px solid #000',
                    // borderLeft: '1px solid #000',
                    // borderRight: '1px solid #000',
                    // borderTop:' 1px solid #000',
                    // borderBottom:' none',
                    // width: `${readerPrint.cardWidth}mm`,
                    // height: `${readerPrint.cardHeight}mm`,
                    width: `${(readerPrint.paperWidth/readerPrint.col)-readerPrint.paddingLeft-readerPrint.paddingRight}mm`,
                    height: `${(readerPrint.paperHeight-readerPrint.paddingTop-readerPrint.paddingBottom-readerPrint.paddingRow)/readerPrint.row}mm`,
                    // height: `${(readerPrint.paperHeight/readerPrint.row)-readerPrint.paddingTop-readerPrint.paddingBottom}mm`,
                    // boxSizing: 'border-box',
                    // paddingTop:`${readerPrint.paddingRow}mm`,
                    // paddingBottom:`${readerPrint.paddingRow}mm`,
                    // paddingLeft:`${readerPrint.paddingRow}mm`,
                    // paddingRight:`${readerPrint.paddingRow}mm`,
                    // paddingTop:`${readerPrint.paddingRow}mm`,
                    // paddingBottom:`${readerPrint.paddingRow}mm`,
                    // paddingLeft:`${readerPrint.paddingRow}mm`,
                    // paddingRight:`${readerPrint.paddingRow}mm`,
                    textAlign: 'center',
                    // position: 'relative',  /* 保证容器位置不受影响 */
                    gap:`${readerPrint.paddingRow}mm`,
                    overflow: 'hidden',   /* 避免文字溢出 */
                    // transform: `translate(${-readerPrint.paddingRow}mm,${-readerPrint.paddingRow}mm)`
                    // boxSizing:'border-box',
                    // textAlign: 'center',
                    // position: 'relative',  /* 保证容器位置不受影响 */
                    // margin: '50px auto' /* 保持容器的位置不变 */
                    }"
                >
               <div style="box-sizing:border-box"
                    :style="{
                    // padding:`${readerPrint.paddingRow}mm ${readerPrint.paddingRow}mm ${readerPrint.paddingRow}mm ${readerPrint.paddingRow}mm`,
                    boxSizing: 'border-box',
                    paddingTop:`${readerPrint.paddingRow}mm`,
                    paddingBottom:`${readerPrint.paddingRow}mm`,
                    paddingLeft:`${readerPrint.paddingRow}mm`,
                    paddingRight:`${readerPrint.paddingRow}mm`,
                    textAlign: 'center',
                    position: 'relative',  /* 保证容器位置不受影响 */
                    overflow: 'hidden',  /* 避免文字溢出 */
                    transform: `translate(0mm,${-readerPrint.paddingRow * 0.8}mm)`
               }">
<!--                                 <div style="box-sizing:border-box">-->
<!--                  <div :style="{  transform: `scale(${readerPrint.paddingRow })`,}">-->
                  <div style="text-align: center; font-size: 14px; font-weight: bold;" >
                    {{printInfo.card}}
                  </div>
                  <hr style="border: 0.5px solid #000; margin: 5px 0;"/>
                  <div style="display: flex; align-items: flex-start;">
                    <div
                        style="width: 33mm; height: 48mm; border: 1px solid #000; margin-right: 5mm;"
                    ></div>
                    <div style="flex-grow: 1; font-size: 12px; line-height: 1.8;text-align: left">
                      <p>姓名：<span style="font-weight: bold;">{{ info.StrName }}</span></p>
                      <p>性别：<span style="font-weight: bold;">{{ info.StrSex === '1' ? '男' : '女' }}</span></p>
                      <p>年级：<span style="font-weight: bold;">{{ info.StrDepartmentName }}</span></p>
                      <p>班级：<span style="font-weight: bold;">{{ info.StrGroupName }}</span></p>
                    </div>
                  </div>
                  <div style="text-align: right; margin-top: -68px;">
<!--                    <canvas :ref="'barcodeCanvas' + index"></canvas>-->
                    <svg :ref="'barcode' + index" style="width: 50%;height: 100%"></svg>
                  </div>
                </div>
                <div class="page-break" v-if="(index + 1) % (readerPrint.row * readerPrint.col) === 0"></div>
              </div>
<!--              </div>-->
            </div>
            </div>
            </div>
          </div>
          </div>
<!--          </div>-->
        </el-dialog>

<!--        <el-dialog-->
<!--            title="预览"-->
<!--            v-model="isPrintShow"-->
<!--            width="210mm"-->
<!--            height="297mm"-->
<!--            @opened="generateBarcodes"-->
<!--        >-->
<!--          <div-->
<!--               :style="{ transform: `scale(${scale / 100})` }"-->
<!--          >-->
<!--            <div id="printCardContainer" ref="printCardContainer">-->
<!--              <div-->
<!--                  :style="{-->
<!--                  display: 'grid',-->
<!--                  // gridTemplateColumns: 'repeat(2, 1fr)',-->
<!--                  // gridTemplateRows:'repeat(3, 1fr)',-->
<!--                  gridTemplateColumns:`repeat(${readerPrint.col},1fr`,-->
<!--                  gridTemplateRows:`repeat(${readerPrint.row},1fr)`,-->
<!--                  padding:`${readerPrint.paddingTop}mm ${readerPrint.paddingRight}mm ${readerPrint.paddingBottom}mm ${readerPrint.paddingLeft}mm`,-->
<!--                  gap:`${readerPrint.paddingRow}mm ${readerPrint.paddingCol}mm`,-->
<!--                }"-->
<!--              >-->
<!--                <div-->
<!--                    v-for="(info, index) in selectedRows"-->
<!--                    :key="index"-->
<!--                    :style="{-->
<!--                    width: `${readerPrint.cardWidth}mm`,-->
<!--                    height: `${readerPrint.cardHeight}mm`,-->
<!--                    }"-->
<!--                    class="print-card"-->
<!--                >-->
<!--                  <div style="text-align: center; font-size: 14px; font-weight: bold;">-->
<!--                    {{info.StrDepartmentName}}-->
<!--                  </div>-->
<!--                  <hr style="border: 0.5px solid #000; margin: 5px 0;"/>-->
<!--                  <div style="display: flex; align-items: flex-start;">-->
<!--                    <div-->
<!--                        style="width: 33mm; height: 48mm; border: 1px solid #000; margin-right: 5mm;"-->
<!--                    ></div>-->
<!--                    <div style="flex-grow: 1; font-size: 12px; line-height: 1.8;text-align: left">-->
<!--                      <p>姓名：<span style="font-weight: bold;">{{ info.StrName }}</span></p>-->
<!--                      <p>性别：<span style="font-weight: bold;">{{ info.StrSex === '1' ? '男' : '女' }}</span></p>-->
<!--                      <p>班级：<span style="font-weight: bold;">{{ info.StrGroupName }}</span></p>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div style="text-align: right; margin-top: -58px;">-->
<!--                    <svg :ref="'barcode' + index"></svg>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="page-break" v-if="(index + 1) % 6 === 0"></div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </el-dialog>-->

<!--        单行打印读者证参数弹窗-->
<!--        <el-dialog-->
<!--            title="参数设置(单位mm)"-->
<!--            v-model="readerCard"-->
<!--            width="35%"-->
<!--            height="50%"-->
<!--            :before-close="closeReaderCard"-->
<!--            :custom-class="'dialog-class'"-->
<!--        >-->
<!--          <el-form :model="readerPrint" size="small">-->
<!--            <el-row :gutter="20">-->
<!--              &lt;!&ndash; 每行第一个数字输入框 &ndash;&gt;-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="纸宽" prop="paperWidth">-->
<!--                  <el-input-number-->
<!--                      v-model="readerPrint.paperWidth"-->
<!--                      :step="1"-->
<!--                      size="small"-->
<!--                      placeholder="请输入打印纸宽度"-->
<!--                      style="width: 100%;"-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->

<!--              &lt;!&ndash; 每行第二个数字输入框 &ndash;&gt;-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="纸高" prop="paperHeight">-->
<!--                  <el-input-number-->
<!--                      v-model="readerPrint.paperHeight"-->
<!--                      :step="1"-->
<!--                      size="small"-->
<!--                      placeholder="请输入打印纸高度"-->
<!--                      style="width: 100%;"-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->

<!--            <el-row :gutter="20">-->
<!--              &lt;!&ndash; 第二行第一个数字输入框 &ndash;&gt;-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="卡宽" prop="cardWidth">-->
<!--                  <el-input-number-->
<!--                      v-model="readerPrint.cardWidth"-->
<!--                      :step="5"-->
<!--                      size="small"-->
<!--                      placeholder="请输入读者卡宽度"-->
<!--                      style="width: 100%;"-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->

<!--              &lt;!&ndash; 第二行第二个数字输入框 &ndash;&gt;-->
<!--              <el-col :span="12">-->
<!--                <el-form-item label="卡高" prop="cardHeight">-->
<!--                  <el-input-number-->
<!--                      v-model="readerPrint.cardHeight"-->
<!--                      :step="1"-->
<!--                      size="small"-->
<!--                      placeholder="请输入读者卡高度"-->
<!--                      style="width: 100%;"-->
<!--                  />-->
<!--                </el-form-item>-->
<!--              </el-col>-->
<!--            </el-row>-->

<!--          </el-form>-->
<!--          <div class="dialog-footer" style="text-align: center;">-->
<!--            <el-button @click="closeReaderCard">取消</el-button>-->
<!--            <el-button type="primary" @click="printReader">确定</el-button>-->
<!--          </div>-->
<!--        </el-dialog>-->

        <!-- 表格结束 -->
        <!-- 分页开始 -->
        <div style="margin-top:50px;text-align:right;">
          <el-pagination
              background
              :total="total"
              :current-page="listQuery.Page"
              :page-size="listQuery.Perpage"
              :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
              layout="prev,pager,next,jumper,sizes,total"
              @size-change="pageSizeChange"
              @current-change="currPageChange"
          />
        </div>
        <!-- 分页结束 -->
      </el-col>
    </el-row>

    <!--    多行打印-->


    <!--        打印区域-->
<!--    <div-->
<!--        v-show="isPrintShow"-->
<!--        id="printCard"-->
<!--        :style="{-->
<!--                width:`${readerPrint.paperWidth}mm`,-->
<!--                height:`${readerPrint.paperHeight}mm`,-->
<!--                }"-->
<!--    >-->
<!--      <div-->
<!--          :style="{-->
<!--                width:`${readerPrint.cardWidth}mm`,-->
<!--                height:`${readerPrint.cardHeight}mm`,-->
<!--            }"-->
<!--      >-->
<!--        <div style="text-align: center; font-size: 14px; font-weight: bold;">-->
<!--          杭州市星洲第二小学-->
<!--        </div>-->
<!--        <hr style="border: 0.5px solid #000; margin: 5px 0;" />-->
<!--        <div style="display: flex; align-items: flex-start;">-->
<!--          <div-->
<!--              style="width: 33mm; height: 48mm; border: 1px solid #000; margin-right: 5mm;"-->
<!--          ></div>-->
<!--          <div style="flex-grow: 1; font-size: 12px; line-height: 1.8;">-->
<!--            <p>姓名：<span style="font-weight: bold;">{{printInfo.name}}</span></p>-->
<!--            <p>性别：<span style="font-weight: bold;">{{ printInfo.sex }}</span></p>-->
<!--            <p>班级：<span style="font-weight: bold;">{{printInfo.class}}</span></p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div style="text-align: right; margin-top: -58px;">-->
<!--          <svg ref="barcode"></svg>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <el-dialog
        title="人脸数据录入"
        v-model="dialogVisible1"
        width="35%"
        height="50%"
        :before-close="handleClose"
        :custom-class="'dialog-class'"
    >
      <div class="media-container">
        <div class="video-container">
          <video ref="video" autoplay style="display: none;"></video>
          <canvas ref="canvas"></canvas>
          <br />缩小
          <input
              type="range"
              v-model="zoomLevel"
              min="1"
              max="4"
              step="0.1"
              @input="updateCanvas"
          />
          <!--                  <label for="zoom">Zoom: {{ zoomLevel }}</label>-->
          <label for="zoom">放大</label>
        </div>

        <!-- 拍照后显示照片 -->
        <!--                <div v-if="photoData" class="photo-container">-->
        <!--                  <h3>采集到的照片数据：</h3>-->
        <!--                  <img :src="photoData" alt="拍摄的照片" />-->
        <!--                </div>-->
      </div>

      <!-- 按钮区域 -->
      <div class="button-container">
        <el-button type="primary" @click="takePhoto">人脸录入</el-button>
        <!--                <el-button type="success" @click="uploadPhoto" :disabled="!photoData">人脸识别</el-button>-->
      </div>
    </el-dialog>

<!--    修改密码 -->
    <el-dialog
        v-model="centerDialogVisible"
        title="修改密码"
        width="350px"
        align-center
    >
      <div>
        <el-input v-model="inputs" style="width: 240px" placeholder="请输入新密码" type="password" show-password/>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="cancelPsw">取 消</el-button>
          <el-button type="primary" @click="submitPsw">
            提 交
          </el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog :title="textMap[dialogStatus]" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :rules="rules"
          :model="formModel"
          size="small"
          label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="头像">
              <el-upload
                  class="avatar-uploader"
                  :headers="picheaders"
                  :class="{disabled:uploadDisabled}"
                  action=""
                  list-type="picture-card"
                  :on-change="handleSuccess"
                  :on-remove="handleRemove"
                  accept=".jpg,.jpeg,.png"
                  :auto-upload="false"
                  :file-list="fileList">
                <i class="el-icon-plus"/>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <template v-if="dialogStatus==='create'">
              <el-form-item label="姓名" prop="StrName">
                <el-input v-model="formModel.StrName"/>
              </el-form-item>
              <el-form-item label="卡号" prop="StrReaderCardNumber">
                <el-input v-model="formModel.StrReaderCardNumber"/>
              </el-form-item>
            </template>
<!--            读者修改时禁止修改姓名和卡号-->
            <template v-else>
              <el-form-item label="姓名" prop="StrName">
                <el-input v-model="formModel.StrName" disabled/>
              </el-form-item>
              <el-form-item label="卡号" prop="StrReaderCardNumber">
                <el-input v-model="formModel.StrReaderCardNumber" disabled/>
              </el-form-item>
            </template>
            <el-form-item label="性别" prop="title">
              <el-radio v-model="formModel.StrSex" label="1">男</el-radio>
              <el-radio v-model="formModel.StrSex" label="0">女</el-radio>
            </el-form-item>
            <el-form-item label="工号" prop="title">
              <el-input v-model="formModel.StrWorkerNumber"/>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="12">
            <el-form-item label="所属单元" prop="StrUnitID">
              <el-select v-model="formModel.StrUnitID" class="filter-item" placeholder="请选择">
                <el-option v-for="item in bmData" :key="item.StrID" :label="item.StrName" :value="item.StrID" />
              </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="12">
            <!-- <el-form-item :prop="isStudent ? 'StrDepartmentID' : 's'" label="部门"> -->
            <el-form-item prop="StrDepartmentID" label="部门">
              <el-select v-model="formModel.StrDepartmentID" placeholder="请选择"
                         @change="njChange(formModel.StrDepartmentID)">
                <el-option
                    v-for="item in njList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <!-- <el-form-item :prop="isStudent ? 'StrGroupID' : 's'" label="小组"> -->
            <el-form-item prop="StrGroupID" label="小组" v-show="isShow">
              <el-select v-model="formModel.StrGroupID" placeholder="请选择" @change="bjChange">
                <el-option v-for="item in bjList" :key="item.StrID" :label="item.StrName" :value="item.StrID"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型" prop="StrReaderTypeID">
              <!--  :disabled="isTeacher || isStudent" -->
              <el-select v-model="formModel.StrReaderTypeName" class="filter-item" placeholder="请选择"
                         @change="getLXStrId">
                <el-option v-for="item in dzlxList" :key="item.StrID" :label="item.StrName" :value="item.StrName"/>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item prop="StrStudyPhase" label="学段">
              <el-select v-model="formModel.StrStudyPhase" placeholder="请选择">
                <el-option v-for="item in xdList" :key="item.strName" :label="item.strName" :value="item.strName" />
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="12">
            <el-form-item label="联系方式">
              <el-input v-model="formModel.StrContact"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电子邮箱">
              <el-input v-model="formModel.StrEmailAddr"/>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注">
              <el-input v-model="formModel.StrRemark" type="textarea"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right">
        <el-button type="primary" size="mini" @click="btnAddorMod">确定</el-button>
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>
</template>

<script>
import {ElMessage, ElMessageBox} from "element-plus";
import BWIPJS from 'bwip-js';
import JsBarcode from 'jsbarcode';
import {read} from "xlsx";
export default {
  data() {
    return {
      scale: 100,
      isPrintShow:false,
      selectedRows: [],
      printInfo:{
        name:'',
        class:'',
        sex:'',
        card:''
      },
      readerPrint:{
        paperWidth:210,
        paperHeight:297,
        cardHeight:100,
        cardWidth:90,
        paddingTop:4,    //上下左右边距
        paddingBottom:4,
        paddingLeft:4,
        paddingRight:4,
        paddingCol:0,   //行列间距
        paddingRow:1,
        row:4,//行数
        col:2 //列数
      },
      readerCard:false,  //读者卡打印弹窗
      currentStrID: null,  // 保存选中的StrID
      inputs:'',
      centerDialogVisible:false,
      visible:false,
      readerNum:null,
      readerName:null,
      zoom:false,//是否支持调节,默认不支持
      canvasWidth: 640, // Canvas 的初始宽度
      canvasHeight: 480, // Canvas 的初始高度
      p5Instance: null, // 存储 p5 实例
      supportsZoom: false,
      maxZoom: 10,
      zoomLevel: 1, // 初始化为最小焦距
      photoURL: null, // 存储拍照后的图片URL
      photoFile: null, // 存储拍照后的图片文件
      photoData: null, // 存储拍照后的图片数据
      stream: null, // 用来存储摄像头视频流
      dialogVisible1: false,//弹窗
      selectDepartmentIndex: 0,
      selectGroupIndex: 0,
      isShow: true,
      list: null,
      total: 0,
      listLoading: true,
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        update: '编辑',
        create: '添加读者'
      },
      rules: {
        StrReaderCardNumber: [
          {required: true, message: '请输入卡号', trigger: 'blur'}
        ],
        StrName: [
          {required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        StrReaderTypeID: [
          {required: true, message: '请选择类型', trigger: 'blur'}
        ],
        StrUnitID: [
          {required: true, message: '请选择单元', trigger: 'blur'}
        ],
        StrLibraryID: [
          {required: true, message: '请选择书屋', trigger: 'blur'}
        ],
        StrDepartmentID: [
          {required: true, message: '请选择部门', trigger: 'blur'}
        ],
        StrGroupID: [
          {required: true, message: '请选择小组', trigger: 'blur'}
        ],
        StrStudyPhase: [
          {required: true, message: '请选择学段', trigger: 'blur'}
        ]
      },
      downloadLoading: false,
      xsSize: 24,
      smSize: 24,
      mdSize: 12,
      lgSize: 12,
      xlSize: 12,
      dialogDZLXVisible: false,
      dialogBMGLVisible: false,
      formModel: {
        AvatarChange: false,
        StrGroupID: '',
        StrGroupName: '',
        StrDepartmentID: '',
        StrDepartmentName: '',
      },
      Query: {},
      swList: [],
      dzlxList: [],
      // bmData: [],
      props: {
        label: 'strNodeName'
      },
      cardList: [
        {
          name: '身份证'
        },
        {
          name: '其他证件'
        }
      ],
      uploadUrl: '',
      fileList: [],
      listQuery: {
        Page: 1,
        Perpage: 10,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      },
      bjList: [],
      njList: [],
      Url: '',
      xdList: [
        {
          strName: '其他'
        },
        {
          strName: '小学'
        },
        {
          strName: '初中'
        },
        {
          strName: '高中'
        },
        {
          strName: '大学'
        },
        {
          strName: '硕士'
        },
        {
          strName: '博士'
        }
      ],
      isStudent: false,
      isTeacher: false
    }
  },
  computed: {
    uploadDisabled: function () {
      if (this.fileList.length > 0) {
        return true
      } else {
        return false
      }
    },
    paginatedRows() {
      const rowsPerPage = 6;  // 每页显示 6 个卡片
      let pages = [];
      for (let i = 0; i < this.selectedRows.length; i += rowsPerPage) {
        pages.push(this.selectedRows.slice(i, i + rowsPerPage));
      }
      return pages;
    }
  },
  created() {
    //根据小组id查询
    if (localStorage.getItem("StrGroupID") !== null && localStorage.getItem("StrGroupID") !== '') {
      this.listQuery.StrGroupID = localStorage.getItem("StrGroupID")
      localStorage.removeItem("StrGroupID")
    }
    if (localStorage.getItem("keyName")) {
      this.listQuery.keyName = localStorage.getItem("keyName")
      localStorage.removeItem("keyName")
    }
    if (localStorage.getItem("keywords")) {
      this.listQuery.keywords = localStorage.getItem("keywords")
      localStorage.removeItem("keywords")
    }
    // this.createGetList()
    this.getList()
    this.getDZLXList()
    // this.getbmData()
    this.fetchReaderGradeList()
  },
  methods: {
    read,
    input() {
      return input
    },
      disabledDate (time)  {
          return time.getTime() > Date.now()
      },
      choose(){
          const day1 = new Date(this.listQuery.DTStartCreatedDate)
          const day2 = new Date(this.listQuery.DTEndCreatedDate)
          if (day2.getTime() < day1.getTime()){
              ElMessage.error('日期选择错误')
              this.listQuery.DTEndCreatedDate = ''
          }
      },
    //点击查询，清除多余条件
    toQuery() {
      this.listQuery.StrGroupID = null
      this.getList()
    },

    //查询图书
    getList() {
      this.listLoading = true
      const param = {
        Page: this.listQuery.Page,
        Perpage: this.listQuery.Perpage,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      }
      if (this.listQuery.StrGroupID != "" && this.listQuery.StrGroupID != null) {
        param.Search["StrGroupID"] = this.listQuery.StrGroupID
      }
      if (this.listQuery.DTStartCreatedDate != "" && this.listQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.listQuery.DTStartCreatedDate + 'T00:00:00.999Z'
      }
      if (this.listQuery.DTEndCreatedDate != "" && this.listQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.listQuery.DTEndCreatedDate + 'T23:59:59.999Z'
      }
      // if(this.listQuery.unitID!=""){
      //   param.Search["StrUnitID"]=this.listQuery.unitID
      // }
      if (this.listQuery.keywords != "" && this.listQuery.keyName != null && this.listQuery.keyName != '' && this.listQuery.keywords != null) {
        param.Search[this.listQuery.keyName] = this.listQuery.keywords
      }
      if (this.listQuery.Status != 0) {
        param.Search["Status"] = this.listQuery.Status
      }
      this.$axios({
        url: this.localpath + 'readers/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.listLoading = false
        }
      })
    },
    // 获取单元
    // getbmData() {
    //   const param = {
    //     OrderBy: {"DTCreatedDate": -1},
    //     Page: 1,
    //     Perpage: 10,
    //   }
    //   this.$axios({
    //     url: this.localpath + 'units/search',
    //     method: 'post',
    //     data: JSON.stringify(param),
    //     //发送格式为json
    //     headers:
    //         {
    //           'Content-Type': 'application/json',
    //           'dataType': 'json',
    //           'Authorization': localStorage.getItem("token")
    //         }
    //   }).then(res => {
    //     if (res.data.ok) {
    //       this.bmData = res.data.msg
    //     }
    //   })
    // },
    // 获取读者类型
    getDZLXList() {
      const param = {
        OrderBy: {"DTCreatedDate": -1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'readertypes/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.dzlxList = res.data.msg
        }
      })
    },
    // 获取部门
    fetchReaderGradeList() {
      const param = {
        OrderBy: {"DTCreatedDate": -1},
        Page: 1,
        Perpage: 10,
      }
      this.$axios({
        url: this.localpath + 'departments/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.njList = res.data.msg
        }
      })
    },
    // 根据部门获取小组
    njChange(val) {

      const param = {
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {"StrDepartmentID": val},
        Page: 1,
        Perpage: 999,
      }
      this.$axios({
        url: this.localpath + 'groups/search',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.bjList = res.data.msg
          this.formModel.StrGroupID = ''
          this.isShow = false
          this.isShow = true
        }
      })
    },
    bjChange() {

    },
    // 获取全部
    getAllList() {
      this.listQuery = {
        Page: 1,
        Perpage: 10,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      },
          this.getList()
    },
    // 获取读者类型id
    getLXStrId(val) {
      this.dzlxList.forEach(item => {
        if (item.StrName === val) {
          this.formModel.StrReaderTypeID = item.StrID
        }
      })
      if (val === '学生') {
        this.isStudent = true
        this.isTeacher = false
      } else if (val === '教师') {
        this.isTeacher = true
        this.isStudent = false
      }
    },
    // 上传图片成功
    handleSuccess(file, fileList) {
      this.fileList = fileList
      this.formModel.AvatarChange = true
      //图片文件转base64
      this.convertImgToBase64(file.raw, base64Str => this.formModel.StrAvatar = base64Str, error => console.log(error));
    },
    // 上传图片移除
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    //转base64
    convertImgToBase64(imageFile, callback, errorCallback) {
      try {
        let reader = new FileReader()
        reader.readAsDataURL(imageFile)
        reader.onload = function (e) {
          if (callback) {
            let base64Str = e.target.result
            callback(base64Str)
          }
        }
      } catch (error) {
        console.error(error)
        if (errorCallback) {
          errorCallback(error)
        }
      }
    },
    // 读者证挂失和停借
    btnGSorTJ(row, val) {
      if (val === 'gs') {
        this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证挂失`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.Status = -1
          this.$axios({
            url: this.localpath + 'readers/' + row.StrID,
            method: 'patch',
            data: JSON.stringify(row),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '挂失成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        })
      } else if (val === 'tj') {
        this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证停借`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.Status = 1
          this.$axios({
            url: this.localpath + 'readers/' + row.StrID,
            method: 'patch',
            data: JSON.stringify(row),
            //发送格式为json
            headers:
                {
                  'Content-Type': 'application/json',
                  'dataType': 'json',
                  'Authorization': localStorage.getItem("token")
                }
          }).then((res) => {
            if (res.data.ok) {
              this.$message({
                message: '停借成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
            this.getList()
          })
        })
      }
    },
    // 取消挂失和停借
    btnQXGSorTJ(row, val) {
      this.$confirm(`是否对卡号${row.StrReaderCardNumber}读者证取消${val}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        row.Status = 0
        this.$axios({
          url: this.localpath + 'readers/' + row.StrID,
          method: 'patch',
          data: JSON.stringify(row),
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$message({
              message: '取消成功',
              type: 'success'
            })
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      })
    },
    //跳转
    goPage(val) {
      if (val) {
        this.$router.push('/type')
      } else {
        this.$router.push('/unite')
      }
    },
    //添加或者编辑
    btnAddorMod() {
      if (this.dialogStatus === 'create') {
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'readers',
              method: 'put',
              // url:this.localpath+'uploadtest',
              // method:'post',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '添加成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      } else {
        for (var i = 0; i < this.njList.length; i++) {
          if (this.formModel.StrDepartmentID === this.njList[i].StrID) {
            this.formModel.StrDepartmentName = JSON.parse(JSON.stringify(this.njList[i].StrName));
            break;
          }
        }
          // eslint-disable-next-line no-redeclare
        for (var i = 0; i < this.bjList.length; i++) {
          if (this.formModel.StrGroupID === this.bjList[i].StrID) {
            this.formModel.StrGroupName = JSON.parse(JSON.stringify(this.bjList[i].StrName))
            break;
          }
        }
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'readers/' + this.formModel.StrID,
              method: 'patch',
              data: JSON.stringify(this.formModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then((res) => {
              if (res.data.ok) {
                this.$message({
                  message: '编辑成功',
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
              this.getList()
            })
            this.dialogFormVisible = false
          }
        })
      }
    },
    //弹出添加对话框
    handleCreate() {
      this.formModel = {
        StrSex: '1',
        AvatarChange: false
      }
      this.fileList = []
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.isStudent = false
      this.isTeacher = false
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    //弹出编辑对话框
    handleUpdate(row) {
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.fileList = []
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
      this.formModel = row
      this.formModel.AvatarChange = false
      if (row.StrReaderTypeName === '教师') {
        this.isTeacher = true
        this.isStudent = false
      } else if (row.StrReaderTypeName === '学生') {
        this.isTeacher = false
        this.isStudent = true
      }
      // this.bmData.forEach(item => {
      //   if (item.StrName === row.StrUnitName) {
      //     this.formModel.StrUnitID = item.StrID
      //   }
      // })
      if (row.StrAvatarURL != "") {
        this.fileList.push({url: this.localpath + 'avatars/' + row.StrAvatarURL})
      } else {
        this.fileList.push({url: this.localpath + 'avatars/default.png'})
      }
      this.njChange(row.StrDepartmentID)
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定删除该读者吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true
      }).then(() => {
        this.$axios({
          url: this.localpath + 'readers/' + row.StrID,
          method: 'delete',
          //发送格式为json
          headers:
              {
                'Content-Type': 'application/json',
                'dataType': 'json',
                'Authorization': localStorage.getItem("token")
              }
        }).then((res) => {
          if (res.data.ok) {
            this.$notify({
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
          } else {
            this.$notify({
              message: res.data.msg,
              type: 'error'
            })
          }
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    pageSizeChange(Perpage) {
      this.listQuery.Perpage = Perpage
      this.getList()
    },
    currPageChange(Page) {
      this.listQuery.Page = Page
      this.getList()
    },
    // 导出
    handleDownload() {
      this.downloadLoading = true
      const param = {
        Page: this.listQuery.Page,
        Perpage: this.listQuery.Perpage,
        Sort: "-1",
        OrderBy: "DTCreatedDate",
        Search: {}
      }
      if (this.listQuery.DTStartCreatedDate != "" && this.listQuery.DTStartCreatedDate != null) {
        param.Search["DTStartCreatedDate"] = this.listQuery.DTStartCreatedDate
      }
      if (this.listQuery.DTEndCreatedDate != "" && this.listQuery.DTEndCreatedDate != null) {
        param.Search["DTEndCreatedDate"] = this.listQuery.DTEndCreatedDate
      }
      if (this.listQuery.unitID != "") {
        param.Search["StrUnitID"] = this.listQuery.unitID
      }
      if (this.listQuery.keywords != "" && this.listQuery.keyName != null) {
        param.Search[this.listQuery.keyName] = this.listQuery.keywords
      }
      if (this.listQuery.Status != 0) {
        param.Search["Status"] = this.listQuery.Status
      }
      this.$axios({
        url: this.localpath + 'readers/export',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(param),
        responseType: 'blob',
        headers:
            {
              'Content-Type': 'application/json',
              dataType: 'json',
              Authorization: localStorage.getItem("token")
            }
      }).then(res => {
        const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'}); //处理文档流
        var data = new Date()
        let timem = data.getTime();
        const fileName = "读者档案" + timem + ".xlsx";
        const elink = document.createElement('a');
        elink.download = fileName;
        elink.style.display = 'none';
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      }).finally(() => {

        this.downloadLoading = false
      })
    },

    async  startDig(row){
      console.log("获取到的状态:"+row.HasBindFace)
      this.readerName = row.StrName;
      this.readerNum = row.StrReaderCardNumber
      this.dialogVisible1 = true; // 打开弹窗
      this.supportsZoom = true;
      this.$nextTick(() => {
        this.setupCamera()
      });

    },
    handleClose(done) {
      this.dialogVisible1 = false
      this.photoData = null; // 清空照片数据
      this.photoURL = null; // 清空照片URL
      this.photoFile = null; // 清空照片文件
      this.$nextTick(() => {
        this.stopCamera()
      });
      done()
    },
    setupCamera() {
      const video = this.$refs.video;

      navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            this.videoStream = stream; // 保存视频流
            video.srcObject = stream;
            video.addEventListener("play", this.startDrawing);

            //  检查摄像头是否支持调焦距(原生的,只能通过调用光学元器件调焦的那种),zoom不为undefined即表明支持
            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();
            console.log("zoom:"+capabilities.zoom)
            if (capabilities.zoom) {
              this.supportsZoom = true;
              this.maxZoom = capabilities.zoom.max || 1; // 设置最大焦距
              this.zoomLevel = capabilities.zoom.min || 1; // 设置最小焦距
              this.zoom = true;
            }

          })
          .catch((err) => {
            console.error("Error accessing the camera: ", err);
            this.$message({ // 错误提示
              message: '访问摄像头失败: ' + err.message,
              type: 'error'
            });
          });
    },
    startDrawing() {
      this.updateCanvas();
    },
    updateCanvas() {
      if(this.zoom === false)
      {
        // ElMessage.info("调用的不是原生的镜头")
        const video = this.$refs.video;
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext("2d");

        // 设置 canvas 尺寸
        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;

        const zoomFactor = this.zoomLevel;

        // 清除 canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // 计算缩放后的视频宽高
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;
        const scaledWidth = videoWidth / zoomFactor;
        const scaledHeight = videoHeight / zoomFactor;

        // 计算偏移量以实现缩放效果
        const offsetX = (videoWidth - scaledWidth) / 2;
        const offsetY = (videoHeight - scaledHeight) / 2;

        // 将视频绘制到 canvas 上
        ctx.drawImage(
            video,
            offsetX,
            offsetY,
            scaledWidth,
            scaledHeight,
            0,
            0,
            canvas.width,
            canvas.height
        );

        // 请求下一帧
        requestAnimationFrame(this.updateCanvas);
      }else {
        const track = this.videoStream.getVideoTracks()[0];
        ElMessage.info("调用的是原生的镜头")
        track.applyConstraints({
          advanced: [{ zoom: this.zoomLevel }],
        })
            .then(() => {
              console.log(`焦距设置为: ${this.zoomLevel}`);
              ElMessage.info(`焦距设置为: ${this.zoomLevel}`)
            })
            .catch((error) => {
              console.error('调整焦距时出错:', error);
              ElMessage.error(error.toString());
            });
      }
    },

    // 关闭摄像头
    stopCamera() {
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => {
          track.stop(); // 停止每个轨道
        });
        this.videoStream = null; // 清空流
      }

      const video = this.$refs.video;
      video.pause();
      video.srcObject = null; // 清除视频流源

      if (this.animationFrameId) {
        cancelAnimationFrame(this.animationFrameId);
        this.animationFrameId = null; // 清空动画帧 ID
      }
    },


    takePhoto() {
      const video = this.$refs.video;
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');

      // 确保画布大小与视频相同
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      // 获取缩放比例
      const zoomFactor = this.zoomLevel;

      // 计算缩放后的绘制区域
      const zoomedWidth = canvas.width / zoomFactor;
      const zoomedHeight = canvas.height / zoomFactor;
      const offsetX = (canvas.width - zoomedWidth) / 2; // 中心绘制的偏移量
      const offsetY = (canvas.height - zoomedHeight) / 2; // 中心绘制的偏移量

      // 清空画布
      context.clearRect(0, 0, canvas.width, canvas.height);

      // 将视频帧绘制到画布上，应用缩放
      context.drawImage(video, offsetX, offsetY, zoomedWidth, zoomedHeight, 0, 0, canvas.width, canvas.height);

      // 获取图像数据URL
      const dataURL = canvas.toDataURL('image/png');

      // 检查 dataURL 是否生成
      console.log('生成的 dataURL:', dataURL);

      // 将 dataURL 转换为 Blob
      fetch(dataURL)
          .then(res => {
            if (!res.ok) {
              throw new Error('无法获取 Blob');
            }
            return res.blob();
          })
          .then(blob => {
            this.photoFile = new File([blob], 'photo.png', { type: 'image/png' }); // 创建文件对象
            this.photoURL = dataURL; // 保存图像URL以便显示
            this.uploadPhoto();
          })
          .catch(err => {
            console.error('转换为 Blob 失败:', err);
          });

      // 预览照片
      this.photoData = dataURL; // 用于显示拍摄的照片
    }
    ,



    // 将 base64 数据转换为 Blob 对象
    dataURLtoBlob(dataURL) {
      const byteString = atob(dataURL.split(',')[1]); // 解码 base64 数据
      const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]; // 获取 MIME 类型

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },

    uploadPhoto() {
      if (!this.photoFile) {
        console.error("没有照片可上传");
        return;
      }
      // 创建 FormData 对象
      const formData = new FormData();
      formData.append('file', this.photoFile, 'photo.png'); // 将 Blob 添加到 FormData 中作为文件
      formData.append('readerNum', this.readerNum); // 添加 readerNum 到表单
      formData.append('readerName', this.readerName); // 添加 readerName 到表单
      console.log("提交的:"+this.readerNum+"||"+this.readerName)
      // 使用 axios 进行文件上传
      this.$axios({
        // url: 'http://192.168.6.139:8000/readers/byface',
        url: this.localpath + 'readers/bindface',
        method: 'post',
        data:formData, // 直接传递 FormData
        headers: {
          'Content-Type': 'multipart/form-data', // 这里可以不设置，axios 会自动设置
          'Authorization': localStorage.getItem("token")
        }
      }).then(res => {
        if (res.data.ok) {
          this.photoData = null; // 上传成功后清空显示的照片
          this.photoFile = null; // 上传成功后清空照片文件
          ElMessage.success('人脸录入成功')
          // console.log("人脸识别后的信息"+res.data.msg.StrReaderCardNumber)
          this.getList();
          this.$nextTick(() => {
            this.handleClose()
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      }).catch(error => {
        ElMessage.error("上传失败，请重试"+error);
        console.error('上传失败:', error);
      });
    },


    unbindFace(row)
    {
      ElMessageBox.confirm(
          '确定解绑人脸信息吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            const formData = new FormData();
            formData.append('readerNum', row.StrReaderCardNumber); // 添加 readerName 到表单
            this.$axios({
              // url: 'http://192.168.6.139:8000/readers/byface',
              url: this.localpath + 'readers/unbindface',
              method: 'post',
              data:formData, // 直接传递 FormData
              headers: {
                'Content-Type': 'multipart/form-data', // 这里可以不设置，axios 会自动设置
                'Authorization': localStorage.getItem("token")
              }
            }).then(res => {
              if (res.data.ok) {
                this.getList();
                ElMessage.success('人脸解绑成功')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
              }
            }).catch(error => {
              ElMessage.error("解绑失败，请重试"+error);
              console.error('解绑失败:', error);
            });

          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消解绑',
            })
          })

    },

    editPsw(row)
    {
      this.currentStrID = row.StrID;
      this.centerDialogVisible = true;
      this.inputs = null;
      //console.log(row.StrID)
      //console.log(this.inputs)
    },
    tets(row) {
      this.isPrintShow = true;
      this.printInfo.name = row.StrName;
      this.printInfo.class = row.StrGroupName;
      this.printInfo.sex = row.StrSex === '1' ? '男' : '女';
      this.printInfo.card = row.StrReaderCardNumber;
      this.readerCard = true;

      // 确保 DOM 更新后再生成条形码
      this.$nextTick(() => {
        const barcodeElement = this.$refs.barcode;
        if (barcodeElement) {
          JsBarcode(barcodeElement, row.StrReaderCardNumber, {
            format: 'CODE128',  // 设置条形码格式
            width: 1,         // 设置条形码宽度
            height: 50,      // 设置条形码高度
            displayValue: true, // 显示条形码文本
          });
        }
      });
    },
    AllPrintReader()
    {
      if(this.selectedRows === null || this.selectedRows === undefined || this.selectedRows.length === 0) {
        alert("请选择要打印的读者信息")
      }else{
        this.printInfo.card = localStorage.getItem('schoolName')
        this.readerCard = true;
      }
      // this.isPrintShow = true;
      // this.$print('#printCardContainer')
    },
    closeReaderCard()
    {
      this.isPrintShow = false;
      this.readerCard = false
    },
    printReader()
    {
      this.readerCard = true
      this.$nextTick(() => {
        this.$print('#printCardContainer')
      })
    },
    preview()
    {
      if(this.isPrintShow === true)
      {
        this.isPrintShow = false;
      }else {
        this.isPrintShow = true;
      }
    },
    // handleSelectionChange(val) {
    //   this.selectedRows = val;
    //   // 确保 Vue 更新 DOM 完成后执行条形码生成
    //   this.$nextTick(() => {
    //     this.selectedRows.forEach((row, index) => {
    //       // 设置延时，避免批量调用 JsBarcode 导致的性能问题
    //       setTimeout(() => {
    //         const barcodeElement = this.$refs['barcode' + index];
    //         if (barcodeElement) {
    //           JsBarcode(barcodeElement, row.StrReaderCardNumber, {
    //             format: 'CODE128',   // 设置条形码格式
    //             width: 2,            // 设置条形码宽度
    //             height: 50,          // 设置条形码高度
    //             displayValue: true,  // 显示条形码文本
    //           });
    //         } else {
    //           console.warn(`无法找到条形码元素：barcode${index}`);
    //         }
    //       }, index * 200); // 延时，确保每个条形码的生成之间有 100ms 的间隔
    //     });
    //   });
    // },

    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    generateBarcodes() {
      // this.isPrintShow = true
      this.$nextTick(() => {
        this.selectedRows.forEach((row, index) => {
          setTimeout(() => {
            const barcodeElement = this.$refs['barcode' + index];
            if (barcodeElement) {
              JsBarcode(barcodeElement, row.StrReaderCardNumber, {
                format: 'CODE39',
                width: 1,
                height: 60,
                displayValue: true,
                renderer: "canvas"
              });
            } else {
              console.warn(`无法找到条形码元素：barcode${index}`);
            }
          }, index * 50); // 设置间隔时间，避免性能问题
        });
      });

    },
    // generateBarcodes() {
    //   // this.isPrintShow = true;
    //   this.$nextTick(() => {
    //     this.selectedRows.forEach((row, index) => {
    //       setTimeout(() => {
    //         const barcodeElement = this.$refs['barcodeCanvas' + index];  // 获取到的Canvas元素
    //         if (barcodeElement) {
    //           // 使用 bwip-js 渲染条形码到 Canvas 元素
    //           BWIPJS.toCanvas(barcodeElement, {
    //             bcid: 'code128',  // 设置条形码类型
    //             text: row.StrReaderCardNumber,
    //             scale: 1,          // 设置条形码大小
    //             height: 20,        // 设置条形码高度
    //             includetext: true  // 显示文本
    //           });
    //         } else {
    //           console.warn(`无法找到条形码元素：barcodeCanvas${index}`);
    //         }
    //       }, index * 100); // 设置间隔时间，避免性能问题
    //     });
    //   });
    // },

    cancelPsw()
    {
      this.centerDialogVisible = false
      this.inputs = null;
    },

    submitPsw()
    {
      ElMessageBox.confirm(
          '确定提交新密码吗？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
          .then(() => {
            // 创建 json 对象
            const data = {
              StrPassword: this.inputs,        // 输入的密码
              StrReaderId: this.currentStrID   // 当前 StrID
            };
            this.$axios({
              url: this.localpath + 'readers/modifyReaderPsw',
              method: 'post',
              data:data,
              headers: {
                'Authorization': localStorage.getItem("token")
              }
            }).then(res => {
              if (res.data.ok) {
                this.getList();
                ElMessage.success('修改成功！')
                this.inputs = null;
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                });
                this.inputs = null;
              }
            }).catch(error => {
              ElMessage.error("修改失败，请重试"+error);
              console.error('修改失败:', error);
              this.inputs = null;
            });
          })
          .catch(() => {
            ElMessage({
              type: 'info',
              message: '取消提交',
            })
          })
      console.log(this.currentStrID)
      this.centerDialogVisible =  false
    }

  }
}
</script>
<style scoped>
.el-button + .el-button {
  margin-left: 4px;
}

.disabled /deep/ .el-upload--picture-card {
  display: none
}

.el-radio {
  margin-right: 10px;
}


.media-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 80px); /* 留出按钮区域的空间 */
}

.video-container {
  flex: 1;
  position: relative;
}

.photo-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-container canvas {
  width: 100%; /* 画布宽度自适应 */
  height: auto; /* 高度根据宽度调整 */
  border: 1px solid #ccc; /* Canvas 边框 */
}

.photo-container img {
  width: 100%; /* 照片宽度自适应 */
  height: auto; /* 高度根据宽度调整 */
  border: 1px solid #ccc; /* 图片边框 */
}

.button-container {
  text-align: center;
  margin-top: 10px;
}
.photo-container h3 {
  margin-top: -40px; /* 调整负值让标题往上移动 */
}




.print-set .print-set-item {
  padding: 5px;
  display: flex;
  align-items: center;
}

.print-set .print-set-item:nth-of-type(odd) {
  background-color: #fafbfc;
}

.print-set .print-set-item:nth-of-type(even) {
  background-color: #eef1f3;
}

.print-set .print-set-item .print-set-title {
  width: 80px;
  font-size: 16px;
  color: #555;
  font-weight: bold;
}

.print-set .print-set-item .print-set-option {
  width: 60px;
  padding: 0 6px;
  text-align: right;
}

.print-box {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  transform-origin: 0 0;
  border-left: 1px solid #000;
}


@media print {
  /* 页面尺寸设置为 A4 */
  @page {
    margin: 0;  /* 设置页面边距 */
    size: A4;  /* 设置页面大小为 A4 */
  }

  /* 打印容器，设置为 2 列 3 行的网格布局 */
  #printCardContainer {
    display: grid;
    gap: 10mm;  /* 设置卡片之间的间距 */
    padding: 10mm; /* 防止内容紧贴边缘 */
    box-sizing: border-box;
  }

  /* 每个卡片的样式 */
  #printCardContainer > div {
    width: 100%;  /* 确保卡片填满容器 */
    page-break-inside: avoid;  /* 防止卡片跨页 */
    box-sizing: border-box;
  }

  /* 强制分页的样式 */
  .page-break {
    page-break-before: always;  /* 强制分页 */
  }

  /* 打印时的 body 样式 */
  body {
    margin: 0;
    padding: 0;
  }



}







</style>
